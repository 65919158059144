import * as React from "react";
const SvgGoodcircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 79 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx={39.5}
      cy={40}
      rx={39.5}
      ry={40}
      fill="#15C139"
      fillOpacity={0.285}
    />
    <ellipse
      cx={40.064}
      cy={40.572}
      rx={34.421}
      ry={34.857}
      fill="#15C139"
      fillOpacity={0.272}
    />
    <ellipse
      cx={40.629}
      cy={41.143}
      rx={30.471}
      ry={30.857}
      fill="#15C139"
      fillOpacity={0.36}
    />
    <ellipse
      cx={40.629}
      cy={41.143}
      rx={30.471}
      ry={30.857}
      fill="#15C139"
      fillOpacity={0.01}
    />
    <ellipse
      cx={40.064}
      cy={40.571}
      rx={25.393}
      ry={25.714}
      fill="#15C139"
      fillOpacity={0.396}
    />
    <ellipse cx={40.629} cy={41.143} rx={20.314} ry={20.571} fill="#15C139" />
  </svg>
);
export default SvgGoodcircle;
