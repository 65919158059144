import * as React from "react";
const SvgStreetlight2 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#streetlight2_svg__a)">
      <path
        d="M21.99 1.198a1.587 1.587 0 0 0-.736-.976 1.586 1.586 0 0 0-1.21-.17l-7.809 2.02V1.35a.236.236 0 0 0-.47 0v.72L3.955.052a1.586 1.586 0 0 0-1.21.17 1.587 1.587 0 0 0-.736.977l-.036.234a1.185 1.185 0 0 0 1.781 1.081c.204-.12.363-.293.463-.5l.598.154a1.587 1.587 0 0 0 1.21-.17c.299-.176.528-.438.66-.752l2.81.727v.199a2.44 2.44 0 0 0-.291-.018.236.236 0 0 0 0 .47c1.083 0 1.964.882 1.964 1.965a.236.236 0 1 0 .471 0 2.46 2.46 0 0 0-.016-.276h.14v6.111a.896.896 0 0 0-.613.843V22.35a1.086 1.086 0 0 0-.848 1.058V24h3.425v-.592c0-.517-.364-.95-.848-1.058V11.248a.859.859 0 0 0-.645-.835v-6.1h.14a2.46 2.46 0 0 0-.015.275.236.236 0 0 0 .47 0c0-1.083.882-1.964 1.965-1.964a.236.236 0 1 0 0-.471c-.099 0-.196.006-.292.018v-.2l2.812-.726a1.601 1.601 0 0 0 1.87.922l.596-.154c.1.207.26.38.463.5a1.176 1.176 0 0 0 .897.126c.306-.079.562-.272.723-.545.12-.203.174-.432.162-.662l-.037-.234Zm-18.474.91a.712.712 0 0 1-1.07-.553l1.3.335a.708.708 0 0 1-.23.217Zm2.271-.517a1.118 1.118 0 0 1-.853.12l-2.385-.617C2.645.9 2.795.74 2.985.628c.26-.153.562-.195.853-.12l2.385.617a1.118 1.118 0 0 1-.436.466Zm4.18.685v-.182l1.797.464v1.283h-.241a2.446 2.446 0 0 0-1.555-1.565Zm3.29 21.132v.12h-2.483v-.12c0-.338.275-.613.613-.613h1.257c.338 0 .613.275.613.613Zm-.849-12.16v11.076h-.785V11.267c0-.11.045-.217.125-.296a.38.38 0 0 1 .268-.115.39.39 0 0 1 .392.392Zm1.624-8.972a2.446 2.446 0 0 0-1.555 1.565h-.242V2.558l1.797-.464v.182Zm5.034-.565a1.119 1.119 0 0 1-.853-.12 1.12 1.12 0 0 1-.436-.466L20.16.508c.292-.076.595-.033.854.12.19.112.34.273.436.466l-2.385.617Zm2.392.144a.707.707 0 0 1-.435.328.707.707 0 0 1-.769-.293l1.299-.336a.706.706 0 0 1-.096.301Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="streetlight2_svg__a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStreetlight2;
