import React from "react";
import "./TextInput.css";
import { useTranslation } from 'react-i18next';

const TextInput = ({
  name,
  openMapHandler,
  maxLength,
  errors,
  register,
  label,
  type,
  mandatory,
  value,
  disabled = false,
}) => {
  const { onChange, ...registerParams } = register(name);
  const { t } = useTranslation();


  return (
    <div className="input_text-form">
      <div className="input_text-form flexbox">

      <label>
      {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      { name === 'latitude' && <span className="facility_maplink--position" onClick={openMapHandler}>
          {t("locateonmap")}
      </span> }                   
      </div>
      <input
        maxLength={maxLength ? maxLength : 40}
        autoComplete="off"
        className={`form-control  ${
          errors[name] ? "error__border--color" : "form__input"
        }`}
        type={type}
        disabled={disabled}
        onChange={(event) => {
          onChange(event);
        }}
        {...registerParams}
      />
      <div className="error__message">{errors[name]?.message}</div>
    </div>
  );
};

export default TextInput;
