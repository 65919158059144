import * as React from "react";
const SvgBulbOn = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#bulb_on_svg__a)" fill="currentColor">
      <path d="M12.6 0v3.6h1.8V0h-1.8ZM6.036 6.564l-2.7-2.7-1.272 1.272 2.7 2.7 1.272-1.272ZM22.236 7.836l2.7-2.7-1.273-1.272-2.7 2.7 1.273 1.272ZM13.5 5.4a7.126 7.126 0 0 0-7.055 6.118l-.018.127a7.112 7.112 0 0 0 1.421 5.381C8.538 17.918 9 18.77 9 19.622V22.5a.9.9 0 0 0 .9.9h7.2a.9.9 0 0 0 .9-.9v-2.878c0-.853.462-1.704 1.152-2.596a7.112 7.112 0 0 0 1.42-5.38l-.017-.128A7.126 7.126 0 0 0 13.5 5.4ZM0 14.4h3.6v-1.8H0v1.8ZM23.4 14.4H27v-1.8h-3.6v1.8ZM10.8 27h5.4v-1.8h-5.4V27Z" />
    </g>
    <defs>
      <clipPath id="bulb_on_svg__a">
        <path fill="currentColor" d="M0 0h27v27H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBulbOn;
