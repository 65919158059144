import React from "react";
import "./Tabs.css";

const DynamicTabs = ({
  tabsData,
  activeTab,
  setActiveTab,
  isNavEnabled = true,
}) => {
  const onClickHandler = (id) => {
    if (isNavEnabled) {
      setActiveTab(id);
    }
  };
  return (
    <div className="tab-container">
      {tabsData?.map((tabData) => (
        <div
          className={`tab ${activeTab === tabData.id && "tab-active"}`}
          onClick={() => onClickHandler(tabData.id)}
          data-isEnabled={isNavEnabled}
        >
          {tabData.name}
        </div>
      ))}
    </div>
  );
};

export default DynamicTabs;
