// import React, { useEffect, useRef } from "react";
// import { GridStack } from "gridstack";
// import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
// import ResourceForm from "./ResourceForm/ResourceForm";
// import ResourceTable from "./ResourceTable/ResourceTable";
// import LMSHeader from "../../LMSHeader/LMSHeader";
// const ResourceList = () => {
//   useEffect(() => {
//     GridStack.init();
//   }, []);

//   const layoutData = [
   
//       {
//           x: 0,
//           y: 0,
//           width: 12,
//           height: 1,
//           id: 1,
//           component: (<LMSHeader title="Resource Management"
//           />
//           ),
//           haveOverflowVisible: true,
//       },
//       {
//           x: 0,
//           y: 1,
//           width: 12,
//           height: 7,
//           id: 1,
//           component: <ResourceForm />, 
//       },
//       {
//           x: 0,
//           y: 8,
//           width: 12,
//           height: 9,
//           id: 2,
//           component: <ResourceTable />,
//       }
//   ];

//   return (
//     <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
//       <GridItem layoutData={layoutData} />
//     </div>
//   );
// };

// export default ResourceList;
