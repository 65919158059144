import React from "react";
import { DownlaodNew } from "../../../../IconComponents";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import LMSButton from "../../../../../Pages/DMS/Common/LMSButton/LMSButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ExportTableData = ({ data, columns, filename, useLMSButton, isExportDisabled, setIsExportDisabled, selectedView, districtData, selectedFromDate, selectedToDate }) => {
  const handleDownload = async () => {
    setIsExportDisabled(false);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
 
function formatDate(date) {
  const formattedDate = new Date(date);
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const year = formattedDate.getFullYear();
  return `${day}-${month}-${year}`;
}

      worksheet.addRow([`Report Name: ${selectedView.toUpperCase()}_REPORT`]);
      worksheet.addRow([`From Date: ${formatDate(selectedFromDate)}`]);
      worksheet.addRow([`To Date: ${formatDate(selectedToDate)}`]);

      worksheet.addRow(columns.map((col) => col.label));
  
    data.forEach((row) => {
      const rowData = columns.map((col) => row[col.label]);
      worksheet.addRow(rowData);
    });
  
    worksheet.mergeCells(1, 1, 1, columns.length);
    worksheet.getCell("A1").alignment = { horizontal: "left", vertical: "middle" };
    worksheet.getCell("A1").font = { bold: true };
  
    worksheet.mergeCells(2, 1, 2, columns.length);
    worksheet.getCell("A2").alignment = { horizontal: "left", vertical: "middle" };
    worksheet.getCell("A2").font = { bold: true };

    worksheet.mergeCells(3, 1, 3, columns.length);
    worksheet.getCell("A3").alignment = { horizontal: "left", vertical: "middle" };
    worksheet.getCell("A3").font = { bold: true };
  
    worksheet.getRow(4).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.font = { bold: true };
    });
  
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 4) {
        row.eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "middle" }; // Center align data rows
        });
      }
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${filename}.xlsx`);
    setIsExportDisabled(true);
  };
  

  const handleIconDownload = async () => {
    // setIsExportDisabled(false);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Add column headers
    worksheet.columns = columns.map((col) => ({
      header: col.label,
      key: col.label,
      width: 20,
    }));

    // Add rows
    data.forEach((row) => {
      const rowData = columns.map((col) => row[col.label]);
      worksheet.addRow(rowData);
    });

    if (filename.startsWith("RoleTable")) {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) { // Skip the header row
          const createdOnCell = row.getCell("Created On");
          // Handle "Created On" column: Convert timestamp to readable date
          if (createdOnCell.value) {
            const timestamp = parseFloat(createdOnCell.value);
            const date = new Date(timestamp);
            createdOnCell.value = date.toLocaleString();
          }
        }
      });
    }
    
    
    
    if (filename.startsWith("DailyFloodReportStatus")) {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          // Skip header row

          const shouldHighlightRow =
            row.getCell("Affected Blocks").value === 0 &&
            row.getCell("Partially AffectedBlocks").value === 0 &&
            row.getCell("NotAffected Blocks").value === 0;

          const areBlocksEqual =
            row.getCell("Total Blocks").value ===
            row.getCell("TotalMarked Blocks").value;

          row.eachCell({ includeEmpty: true }, (cell) => {
            if (shouldHighlightRow) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" }, // Red
              };
            } else if (areBlocksEqual) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF00FF00" }, // Light green
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF00" }, // Yellow
              };
            }
          });
        }
      });
    }

    // Generate the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${filename}.xlsx`);
    // setIsExportDisabled(true);
  };

  const exportTooltip = (
    <Tooltip id="export-tooltip">Export will be enabled once saved</Tooltip>
  );
  

  return (
    <>
      {useLMSButton ? (
   isExportDisabled ? (
    <OverlayTrigger placement="top" overlay={exportTooltip}>
      <span>
        <LMSButton
          size="btn_sm"
          label="Export"
          onClick={handleDownload}
          variant="primary"
          disabled={isExportDisabled}
          style={{
            opacity: isExportDisabled ? 0.2 : 1,
            transition: "opacity 0.3s ease",
          }}
        />
      </span>
    </OverlayTrigger>
  ) : (
    <LMSButton
            size="btn_sm"
            label="Export"
            onClick={handleDownload}
            variant="primary"
            disabled={isExportDisabled}
            style={{
              opacity: isExportDisabled ? 0.2 : 1,
              transition: "opacity 0.3s ease",
            }}
          />
        )
   
       ) : (
       <div style={{ margin: "0.3em" }}>
          {data?.length > 0 && (
       <button
              onClick={handleIconDownload}
              style={{ background: "none", border: "none" }}
         >
         <DownlaodNew
                color="var(--white3-color)"
                width="1.2em"
                height="1.2em"
              />
        </button>
          )}
       </div>
      )}
    </>
  );
};

export default ExportTableData;
