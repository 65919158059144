import * as React from "react";
const SvgVolume = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        clipRule="evenodd"
        d="M3.957 12c-.003 1.23-.057 2.907.695 3.534.702.585 1.196.434 2.477.53 1.282.095 3.987 3.906 6.073 2.7 1.076-.855 1.156-2.649 1.156-6.764s-.08-5.909-1.156-6.764C11.116 4.029 8.411 7.84 7.129 7.937c-1.281.095-1.775-.055-2.477.53-.752.626-.698 2.303-.695 3.533Z"
      />
      <path d="M18.374 8.314a7.471 7.471 0 0 1 0 7.372" />
    </g>
  </svg>
);
export default SvgVolume;
