import React from 'react'
import "./StatusHandler.css"

export default function StatusHandler({ props, status }) {
  return props.value === "false" ? (
    <div className="inactivestatus">
      <div id='inactive_dot'></div>
      <div>Pending</div>
    </div>
  ) : props.value === "Blocked" ? (
    <div className="blockedstatus">
      {props.value}
    </div>
  ) : (
    <div className="activestatus">
      <div id='active_dot'></div>
      <div>Completed</div>
    </div>
  );
  //  props.value === status ? (
  //   <div className="inactivestatus">
  //     {props.value}
  //   </div>
  // ) : (
  //   <div className="activestatus">
  //     {props.value}
  //   </div>
  // );
}
