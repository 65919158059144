import React, { useEffect } from 'react'
import "./GisManagement.css";

const ExecuteSOP = () => {
  const url = process.env.REACT_APP_GIS_SERVICE + "/executesop";
  return (
    <div className='iframecontainer'>
    <iframe src={url} sandbox='allow-scripts allow-modal'>
    </iframe>
    </div>
  );
}

export default ExecuteSOP