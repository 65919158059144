import React from "react";
import Modal from "react-bootstrap/Modal";
import "./ModalCreateResource.css";
import XCircleblack from "../../IconComponents/XCircleblack";

const ModalCreateResource = (props) => {
  const { title, titleValue, show, handleToggle, size } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggle}
        size={size}
        className="table__modal__popup"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {title}{titleValue}
          </Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={handleToggle}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div class="form-group">
              <label  className="create__resource--label" for="tfld_resource_category">Add Resource Category</label>
              <input type="text" class="form-control w-100" id="tfld_resource_category" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            style={{ backgroundColor: "#40B2B7" }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCreateResource;
