import React from "react";
import "./MapPopUp.css";
//import { getFormattedDate } from "../../../../Components/UiComponents/utils";

const MapPopUp = ({ popupvalue }) => {
  //const date = getFormattedDate(popupvalue.installation);

  const popupData = [
    { label: "Asset Type", name: popupvalue.assetType },
    { label: "Device Make", name: popupvalue.deviceMake },
    { label: "Device Model", name: popupvalue.deviceModel },
    { label: "Serial Number", name: popupvalue.serialNumber },
    //{ label: "Installed On", name: date },
    { label: "Location Name", name: popupvalue.name },
    { label: "Status", name: popupvalue.status },
  ];

  return (
    <div className="map__popup--container">
      <div className="map__popup--title">Asset Details :</div>

      {popupData.map((item, index) => (
        <div className="map__popup--subtitle" key={index}>
          <div className="labelDiv">
            <p className="popUpLabel">{item.label} :</p>
          </div>
          <div>
            {item.label === "Status" ? (
              item.name === "Inactive" ? (
                <div className="inactivestatus">
                  <div id="inactive_dot"></div>
                  <div>{item.name}</div>
                </div>
              ) : (
                <div className="activestatus">
                  <div id="active_dot"></div>
                  <div>{item.name}</div>
                </div>
              )
            ) : (
              <p className="popUpKey">{item.name}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MapPopUp;
