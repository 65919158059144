import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Editicon, Deleteicon } from "../../../../../Components/IconComponents";
import Table from "../../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import { getData, putDataWithBody } from "../../../../../Services/Services";
import {
  regionListURL,
  deleteRegionURL,
} from "../../../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../../Common/StatusHandler/StatusHandler";
import { regioncolumnsForExport } from "../../../../../JsonData/RegionConfig";
import { setTableData } from "../../../../../CentralStore/ExportLMSTable/dataSlice";
import { getExportedColumnsData } from "../../../../../Components/UiComponents/utils";
import { useDispatch } from "react-redux";
const filterOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const RegionTable = forwardRef((props, ref) => {
  const qs = require("qs");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("Active");
  const [regionData, setRegionData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    getRegionList();
  }, []);
  const getRegionList = async () => {
    var regionList = await getData(regionListURL);
    setRegionData(regionList);
    setRowCount(regionList.length);
    sendDataToCentralStore(regionList);
  };

  const addNewHandler = () => {
    props.showCreateRegionScn();
  };
  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = regioncolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: "Created On",
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: "Updated On",
        accessor: "updated_on",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
      // {
      //     Header: "Status  ",
      //     accessor: "status",
      //     disableSortBy: true,
      //     Cell: (props) => {
      //         return <StatusHandler props={props} />;
      //     },
      // },
      {
        Header: "Action",
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteRegionURL, data, {});
            if (res.status == "success") {
              getRegionList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span onClick={deletePopUp} className="ms-3">
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  //   data={data}
                  title="Confirm delete"
                  message="Are you sure you want to delete"
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel="Cancel"
                  action="Delete"
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="container  create-user">
      <div className="datatable__container">
        {regionData && (
          <Table
            data={regionData}
            columns={columns}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            //filterOptions={filterOptions}
            numberOfRows={10}
            addButtonLabel="Create Region"
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={true}
            showReset={false}
            onExpand={false}
            rowCount = { rowCount } // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default RegionTable;
