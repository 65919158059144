import * as React from "react";
const SvgPlayRedIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 13.983c3.59 0 6.5-2.915 6.5-6.511S11.09.961 7.5.961 1 3.876 1 7.472s2.91 6.511 6.5 6.511Z"
      stroke="#DE8080"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m6 4.968 4 2.504-4 2.504V4.968Z"
      fill="#DE8080"
      stroke="#DE8080"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPlayRedIcon;
