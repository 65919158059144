import * as React from "react";
const SvgPtz8 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.007 5.026-7.944 4.99 7.93 5.012.014-10.002Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.447 11.542-1.763-1.295a.3.3 0 0 1-.001-.483l1.759-1.3"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz8;
