import * as React from "react";
const SvgInfoSquare = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.209 1.719H4.79c-1.888 0-3.072 1.337-3.072 3.228v5.106c0 1.891 1.178 3.228 3.072 3.228h5.417c1.895 0 3.073-1.337 3.073-3.229V4.949c0-1.892-1.178-3.23-3.072-3.23Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.497 10V7.5M7.494 5.128H7.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgInfoSquare;
