import * as React from "react";
const SvgWip = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx={6.375}
      cy={6.345}
      rx={6.344}
      ry={6.345}
      fill="url(#wip_svg__a)"
    />
    <defs>
      <linearGradient
        id="wip_svg__a"
        x1={6.375}
        y1={-6.345}
        x2={-6.315}
        y2={6.343}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9722FB" />
        <stop offset={1} stopColor="#3A5DFB" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgWip;
