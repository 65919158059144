import * as React from "react";
const SvgEventsIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#events-icon_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.5 1.25h-10c-.69 0-1.25.56-1.25 1.25V5c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V2.5c0-.69-.56-1.25-1.25-1.25ZM12.5 8.75h-10c-.69 0-1.25.56-1.25 1.25v2.5c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V10c0-.69-.56-1.25-1.25-1.25ZM3.75 3.75h.006M3.75 11.25h.006" />
    </g>
    <defs>
      <clipPath id="events-icon_svg__a">
        <path fill="currentColor" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEventsIcon;
