import * as React from "react";
const SvgBadge = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.301 8.398a4.199 4.199 0 1 0 0-8.398 4.199 4.199 0 0 0 0 8.398Z"
      fill="#E95060"
    />
  </svg>
);
export default SvgBadge;
