import React from "react";
import "./RadioButtonGroup.css";
const RadioButtonGroup = ({ name, data, register, errors,selectedValue,onChange }) => {
  return (
    <div>
      {data.map((option) => (
        <div className="radio__item">
          <input
            key={option.value}
            type="radio"
            name={name}
            id={option.value}
            value={option.value}
            checked={selectedValue}
            
            {...register(name)}
            onChange={(e) => onChange(e.target.value)}
          />
          <label htmlFor={option.value}>{option.value}</label>
        </div>
      ))}
      <p className="error__message">{errors[name]?.message}</p>
    </div>
  );
};

export default RadioButtonGroup;
