import * as React from "react";
const SvgBarrierClosed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 35 25"
    {...props}
  >
    <path
      fill="#B1B1B1"
      d="M31.5 1.74H10.885C8.943-.435 5.617-.594 3.5 1.385a5.176 5.176 0 0 0-1.75 3.887v15.9c-.464 0-.91.186-1.237.517-.329.331-.513.78-.513 1.25v1.766h14v-1.767c0-.468-.184-.918-.513-1.249a1.742 1.742 0 0 0-1.237-.517V8.806H31.5c.928 0 1.819-.372 2.475-1.035A3.55 3.55 0 0 0 35 5.273a3.55 3.55 0 0 0-1.025-2.499A3.483 3.483 0 0 0 31.5 1.74ZM7 7.922c-.696 0-1.364-.28-1.856-.776a2.663 2.663 0 0 1-.769-1.874c0-.703.277-1.377.769-1.874A2.613 2.613 0 0 1 7 2.623c.696 0 1.364.279 1.856.776s.769 1.17.769 1.874c0 .703-.277 1.377-.769 1.874A2.613 2.613 0 0 1 7 7.923Zm7.875-.884L12.25 3.506h2.625L17.5 7.04h-2.625Zm7 0L19.25 3.506h2.625L24.5 7.04h-2.625Zm7 0L26.25 3.506h2.625L31.5 7.04h-2.625Z"
    />
  </svg>
);
export default SvgBarrierClosed;
