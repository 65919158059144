import * as React from "react";
const SvgLeaf = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1 11.672C17.1 8.266 15.986.679 15.938.357a.418.418 0 0 0-.56-.33c-.116.042-2.882 1.08-5.694 3.015C8.021 4.186 6.693 5.406 5.736 6.67 4.518 8.278 3.9 9.96 3.9 11.672c0 3.198 2.288 5.872 5.312 6.473.147.932.35 1.795.605 2.567a.418.418 0 1 0 .794-.261 15.277 15.277 0 0 1-.532-2.194 6.607 6.607 0 0 0 7.02-6.586Zm-12.363 0c0-3.477 2.941-6.232 5.408-7.932 1.619-1.115 3.252-1.934 4.318-2.419-.885 1.278-2.62 3.948-3.655 6.617a.418.418 0 0 0 .78.303c1.04-2.684 2.87-5.447 3.685-6.615.317 2.3.99 7.478.99 10.046a5.77 5.77 0 0 1-5.763 5.763 5.77 5.77 0 0 1-5.763-5.763Z"
      fill="currentColor"
    />
    <path
      d="M10.615 9.898a.418.418 0 0 0-.49.332 7.471 7.471 0 0 0-.138 1.277.418.418 0 0 0 .412.424h.006a.418.418 0 0 0 .418-.412c.005-.346.047-.727.125-1.132a.418.418 0 0 0-.333-.49Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLeaf;
