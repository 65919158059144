import * as React from "react";
const SvgMenu = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 4 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={2} cy={2} r={2} fill="#D9D9D9" />
    <circle cx={2} cy={9} r={2} fill="#D9D9D9" />
    <circle cx={2} cy={16} r={2} fill="#D9D9D9" />
  </svg>
);
export default SvgMenu;
