import * as React from "react";
const SvgWatt = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 38 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.762 15.523c-.491 0-.937-.292-1.132-.746l-4.14-9.66-3.326 9.579c-.171.491-.633.823-1.153.827H19c-.516 0-.978-.322-1.156-.807l-3.447-9.39-3.35 9.38a1.232 1.232 0 0 1-2.303.046L6.16 8.332H0V5.87h6.991c.503 0 .955.306 1.143.772l1.684 4.182 3.403-9.529a1.232 1.232 0 0 1 1.155-.817h.005c.516 0 .978.322 1.156.807l3.43 9.345 3.239-9.325a1.232 1.232 0 0 1 2.295-.08l4.08 9.518 1.252-4.008c.16-.514.637-.865 1.176-.865h6.99v2.464h-6.085l-1.977 6.326a1.232 1.232 0 0 1-1.175.864Z"
      fill="#000"
    />
  </svg>
);
export default SvgWatt;
