import * as React from "react";
const SvgPlayMedia = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.888 18.334c4.551 0 8.24-3.731 8.24-8.334 0-4.602-3.689-8.333-8.24-8.333-4.55 0-8.24 3.731-8.24 8.333 0 4.603 3.69 8.334 8.24 8.334Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.24 6.667 13.184 10 8.24 13.334V6.667Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPlayMedia;
