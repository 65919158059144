import * as React from "react";
const SvgLocation = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.5 8.5a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M9 19C7.8 19 1.5 13.898 1.5 8.563 1.5 4.387 4.857 1 9 1c4.142 0 7.5 3.387 7.5 7.563C16.5 13.898 10.198 19 9 19Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLocation;
