import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";

const FloodReliefCampTrendView = (props) => {
  const echartsDom = useRef(null);
  const [chart, setChart] = useState(null);
  const { t } = useTranslation();

  console.log(props);
  const colors = ["#F4694C", "#40B2B7"];
  let levels = [];
  const data = {
    facility_type: t("navlinklabels.floodcamp"),
    attributes: [
      {
        name: t("halogentablets"),
        count: props?.dataval["total_halogen_tablets"]
          ? props?.dataval["total_halogen_tablets"]
          : 0,
      },
      {
        name: t("bleachingpowder"),
        count: props?.dataval["total_bleaching_powder"]
          ? props?.dataval["total_bleaching_powder"]
          : 0,
      },
    ],
  };
  const options = {
    tooltip: {
      confine: true,
    },
    xAxis: {
      type: "value",
      axisLine: { color: "#fff" },
      splitLine: {
        show: false,
      },
    },
    grid: {
      top: "10%",
      bottom: "5%",
      left: "30%",
      right: "5%",
    },
    yAxis: {
      type: "category",
      data: levels,
      axisLabel: {
        color: "#fff",
        width: 60,
        overflow: "break",
      },
      axisLine: { onZero: false },
      splitLine: {
        show: false,
      },
    },
  };
  useEffect(() => {
    const myChart = echarts.init(echartsDom.current); // echarts theme
    //chart.setOption({ ...options, resizeObserver }, true);
    let dataseries = [];
    data.attributes.forEach((element, index) => {
      levels.push(element["name"]);
      let row = {
        value: element["count"],
        itemStyle: { borderRadius: [0, 30, 30, 0], color: colors[index] },
      };
      dataseries.push(row);
    });
    let series = {
      data: dataseries,
      type: "bar",
      label: {
        show: true,
      },
    };
    options.series = [series];
    myChart.setOption(options);
  }, [props.dataval, t]);
  return (
    <div class="card" style={{ width: "100%" }}>
      <div class="card-body">
        <div class="card-title">
          {t("navlinklabels.floodcamp")}
          <span className="float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </span>
        </div>
        <div ref={echartsDom} style={{ height: 160, width: "100%" }} />
      </div>
    </div>
  );
};
export default FloodReliefCampTrendView;
