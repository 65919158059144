import * as React from "react";
const SvgRefresh = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.634.567 7.367 2.834m0 0L9.634 5.1M7.367 2.834h3.4a3.4 3.4 0 0 1 3.4 3.4V11.9M2.834 5.1v5.667a3.4 3.4 0 0 0 3.4 3.4h2.833m-1.7 2.267 2.267-2.267L7.367 11.9M2.834 5.1a2.267 2.267 0 1 0 0-4.533 2.267 2.267 0 0 0 0 4.533Zm11.333 6.8a2.267 2.267 0 1 0 0 4.534 2.267 2.267 0 0 0 0-4.534Z"
      stroke="currentColor"
    />
  </svg>
);
export default SvgRefresh;
