import * as React from "react";
const SvgBarrierOpen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#17A3A0"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M28.576 1.605A3.193 3.193 0 0 0 24.192.437L7.872 9.859A4.67 4.67 0 0 0 6.4 9.603a4.8 4.8 0 0 0-4.8 4.8V28.8A1.6 1.6 0 0 0 0 30.4V32h12.8v-1.6a1.6 1.6 0 0 0-1.6-1.6V15.33l16.192-9.358a3.196 3.196 0 0 0 1.184-4.367ZM6.4 16.802a2.4 2.4 0 1 1 0-4.8 2.4 2.4 0 0 1 0 4.8Zm7.04-4.607-3.68-1.6 2.08-1.2 3.68 1.6-2.08 1.2Zm5.536-3.2-3.68-1.6 2.08-1.2 3.68 1.6-2.08 1.2Zm5.552-3.2-3.68-1.599 2.08-1.2 3.664 1.6-2.064 1.2ZM24 14.404l8 7.998h-4.8v6.4h-6.4v-6.4H16l8-7.998Z" />
  </svg>
);
export default SvgBarrierOpen;
