import * as React from "react";
const SvgTableView = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.9em"
    height="1em"
    fill="none"
    viewBox="0 0 50 30"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.167 8.938c0-3.61.039-4.813 4.86-4.813 4.823 0 4.862 1.203 4.862 4.813 0 3.61.015 4.812-4.861 4.812-4.877 0-4.862-1.203-4.862-4.813ZM19.444 8.938c0-3.61.04-4.813 4.861-4.813 4.823 0 4.862 1.203 4.862 4.813 0 3.61.015 4.812-4.862 4.812-4.876 0-4.86-1.203-4.86-4.813ZM4.167 24.063c0-3.61.039-4.813 4.86-4.813 4.823 0 4.862 1.203 4.862 4.813 0 3.61.015 4.812-4.861 4.812-4.877 0-4.862-1.203-4.862-4.813ZM19.444 24.063c0-3.61.04-4.813 4.861-4.813 4.823 0 4.862 1.203 4.862 4.813 0 3.61.015 4.812-4.862 4.812-4.876 0-4.86-1.203-4.86-4.813Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTableView;
