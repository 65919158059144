import * as React from "react";
const SvgPolicesIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.198 13.5c0 .3-.299.601-.599.5H2.3C1.001 14 0 13 0 11.8V2.2C0 1 .999 0 2.3 0h7.001c1.2 0 2.298 1 2.298 2.2v3.3c0 .3-.298.5-.599.5s-.5-.302-.5-.5V2.2c0-.599-.5-1.099-1.101-1.099H2.398c-.599 0-1.101.5-1.101 1.099v9.601c0 .599.5 1.099 1.101 1.099h1.3c.199-.001.5.201.5.6Z"
      fill="#F0F0F7"
    />
    <path
      d="M7.798 3.8c0-.2-.201-.403-.4-.403h-4c-.202 0-.4.201-.4.403s.199.399.4.399h4c.199-.098.4-.199.4-.399ZM5.798 6c0-.198-.201-.399-.4-.399h-2.5c-.201 0-.4.2-.4.4s.2.4.4.4h2.5c.199-.002.4-.2.4-.4ZM2.799 7.8c-.202 0-.4.2-.4.4s.199.402.4.402h1.6c.199 0 .4-.202.4-.403 0-.202-.202-.399-.4-.399h-1.6ZM12.399 13.7c-.1.2-.4.3-.599.3H7.999c-.4 0-.8-.399-.8-.799v-.199c.201-.899.8-1.601 1.5-1.899-.199-.301-.4-.601-.4-.999 0-.8.701-1.5 1.6-1.5.8 0 1.501.7 1.501 1.5 0 .399-.1.7-.4.999.8.401 1.299 1.099 1.5 1.899.098.298.098.498-.101.698Z"
      fill="#F0F0F7"
    />
  </svg>
);
export default SvgPolicesIcon;
