import * as React from "react";
const SvgClearAudioIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#ClearAudioIcon_svg__a)"
      stroke="#EB5757"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.5 13.75a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5ZM9.375 5.625l-3.75 3.75M5.625 5.625l3.75 3.75" />
    </g>
    <defs>
      <clipPath id="ClearAudioIcon_svg__a">
        <path fill="currentColor" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClearAudioIcon;
