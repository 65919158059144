import * as React from "react";
const SvgArrowLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arrow_left_svg__a)">
      <path
        d="m15 18-6-6 6-6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="arrow_left_svg__a">
        <path
          fill="currentColor"
          transform="rotate(-180 12 12)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowLeft;
