import * as React from "react";
const SvgGreendropdownarrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15 1.5-7 7-7-7"
      stroke="#17A3A0"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgGreendropdownarrow;
