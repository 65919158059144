import * as React from "react";
const SvgEllipsis = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 5 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.154 1.777c0 .982-.93 1.778-2.077 1.778C.93 3.555 0 2.759 0 1.777 0 .796.93 0 2.077 0c1.147 0 2.077.796 2.077 1.777Zm0 7.11c0 .981-.93 1.777-2.077 1.777C.93 10.664 0 9.868 0 8.887c0-.982.93-1.778 2.077-1.778 1.147 0 2.077.796 2.077 1.778Zm-2.077 8.887c1.147 0 2.077-.796 2.077-1.778 0-.981-.93-1.777-2.077-1.777C.93 14.219 0 15.015 0 15.996c0 .982.93 1.778 2.077 1.778Z"
      fill="#54617A"
    />
  </svg>
);
export default SvgEllipsis;
