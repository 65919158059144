import * as React from "react";
const SvgBarChart = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#bar-chart_svg__a)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
    >
      <path d="M5.696 5.793v-.1H2.604V13.045h3.092V5.793ZM4.91 12.26H3.39v-5.78h1.52v5.78ZM9.8 8.1V8H6.708V13.046H9.8V8.1Zm-.787 4.16h-1.52V8.785h1.52v3.473ZM13.942 10.249v-.1H10.85V13.046h3.092v-2.797Zm-.786 2.01h-1.52v-1.324h1.52v1.324Z" />
      <path d="m14.904 13.142-.07-.072-.071.07-.422.407-.072.07.07.071.355.369H1.593V1.723l.343.344.07.07.072-.07.414-.415.07-.07-.07-.071L1.282.3 1.21.23 1.14.3-.07 1.51l-.071.071.07.071.415.415.07.07.071-.07.321-.322V14.843H14.61l-.301.301-.07.07.07.072.414.414.071.07.07-.07 1.208-1.207.07-.07-.069-.07-1.168-1.21Z" />
    </g>
    <defs>
      <clipPath id="bar-chart_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBarChart;
