import * as React from "react";
const SvgDatepicker = ( props ) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 3.25h-.9c-1.26 0-1.89 0-2.372.245-.423.216-.767.56-.983.983C1 4.96 1 5.59 1 6.85v.9m4.5-4.5h9m-9 0V1M1 7.75v9.9c0 1.26 0 1.89.245 2.371.216.424.56.768.983.984.481.245 1.11.245 2.369.245h10.806c1.258 0 1.887 0 2.368-.245.423-.216.768-.56.984-.984.245-.48.245-1.11.245-2.367V7.75m-18 0h18m-4.5-4.5h.9c1.26 0 1.89 0 2.37.245.424.216.77.56.985.983C19 4.96 19 5.588 19 6.847v.903m-4.5-4.5V1m0 15.75h.002v.002H14.5v-.002Zm-4.5 0h.002v.002H10v-.002Zm-4.5 0h.002v.002H5.5v-.002Zm9.002-4.5v.002H14.5v-.002h.002Zm-4.502 0h.002v.002H10v-.002Zm-4.5 0h.002v.002H5.5v-.002Z"
      stroke="#17A3A0"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDatepicker;
