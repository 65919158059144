import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import Table from "../../../../Components/UiComponents/Table/Table";
// import "../DMSDashboard.css";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
    getFacilityReportAttrByReportIdAndCat
} from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";
const HLTHCamp = (props) => {
  const [reportAttributeData, setReportAttributeData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [tableData,setTableData] = useState([]);
  const { t } = useTranslation();
  const [healthCampData,setHealthCampData] = useState([]);
  const [operationalData,setOperationalData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getReportAttributeData();
  }, []);

  const getReportAttributeData = async () => {
    let input = {"category_cd":props.categoryCd,"facility_report_id":props.facilityReportId};
    var raDataList = await postDataWithBody(getFacilityReportAttrByReportIdAndCat, input, {});
    let hlthCampData = [];
    let operationalCampData = [];
    raDataList.forEach(function(data,node){
        if(data.attribute_code.startsWith("HLTH_RPRT_NO")){
            hlthCampData.push(data)
        }
        else if(data.attribute_code.startsWith("OP_RPRT_NO")){
            operationalCampData.push(data)
        }
    });
    setHealthCampData(hlthCampData);
    setOperationalData(operationalCampData);
    setRowCount(hlthCampData.length);
    
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t('hlthcamppopup.slno'),
        accessor: "sno",
        disableSortBy: true,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t('hlthcamppopup.type'),
        accessor: "attribute"
      },
      {
        Header: t('hlthcamppopup.quantity'),
        accessor: "facility_report_attribute_value"
      }
    ],
    [t]
  );


  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {healthCampData && (
            <Table
              title={t('hlthcampdetails')}
              data={healthCampData}
              columns={columns}
              numberOfRows={10}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              disbalePaginationFilter={true}
              disbalePaginationFilter1={true}
              disbalePaginationFilter2={true}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
      <div className="container clear-both create-user">
        
        <div className="datatable__container">
        
          {operationalData && (
            <Table
              title={t('operationaldetails')}
              data={operationalData}
              columns={columns}
              numberOfRows={10}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              disbalePaginationFilter={true}
              disbalePaginationFilter1={true}
              disbalePaginationFilter2={true}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
    </div>

    
  );
};

export default HLTHCamp;
