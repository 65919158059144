import * as React from "react";
const SvgCustomMarker = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#custom-marker_svg__a)">
      <ellipse cx={34.5} cy={71} rx={14.5} ry={7} fill="currentColor" />
      <g filter="url(#custom-marker_svg__b)">
        <path
          d="M58 35.545C58 54.636 35 71 35 71S12 54.636 12 35.545c0-6.51 2.423-12.753 6.736-17.356C23.05 13.586 28.9 11 35 11s11.95 2.586 16.264 7.19C55.577 22.791 58 29.035 58 35.544Z"
          fill="currentColor"
        />
        <path
          d="M58 35.545C58 54.636 35 71 35 71S12 54.636 12 35.545c0-6.51 2.423-12.753 6.736-17.356C23.05 13.586 28.9 11 35 11s11.95 2.586 16.264 7.19C55.577 22.791 58 29.035 58 35.544Z"
          stroke="#FF4D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M52.25 36C52.25 50 35 62 35 62S17.75 50 17.75 36c0-4.774 1.817-9.352 5.052-12.728C26.037 19.896 30.425 18 35 18s8.963 1.896 12.198 5.272C50.433 26.648 52.25 31.226 52.25 36Z"
        fill="#FF4D2C"
        stroke="#FF4D2C"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 42c3.176 0 5.75-2.686 5.75-6s-2.574-6-5.75-6-5.75 2.686-5.75 6 2.574 6 5.75 6Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="custom-marker_svg__a">
        <path fill="currentColor" d="M0 0h60v110H0z" />
      </clipPath>
      <filter
        id="custom-marker_svg__b"
        x={7.5}
        y={10.5}
        width={55}
        height={76}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_210_2139"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_210_2139"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgCustomMarker;
