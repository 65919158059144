import React, { useState, createContext, useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LayoutContext from "./Context/LayoutContext/LayoutContext";
import MapMarkerContext from "./Context/MapMarkerContext/MapMarkerContext";
import AppContext from "./Context/AppContext/AppContext";
import { Provider } from "react-redux";
import store from "./CentralStore/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import "./i18n";
import { getData } from "./Services/ProfileServices";
import {
  createUserLog,
  findRolesByUser,
  getThemeURL,
  getUsersFromApp,
  regionListURL,
} from "./Services/EndPoints";
import { toast } from "react-toastify";
import { postDataWithBody } from "./Services/Services";
import axios from "axios";

export const ThemeContext = createContext(null);
export const RoleContext = createContext();
const root = ReactDOM.createRoot(document.getElementById("root"));

function AppComponent() {
  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "login-required" }}
      authClient={keycloak}
      onTokens={({ token }) => {
        localStorage.setItem("auth_token", token);
        sessionStorage.setItem("auth_token",token);
        localStorage.setItem("user_obj_data", JSON.stringify(keycloak));
      }}
    >
      <LayoutContextComponent />
    </ReactKeycloakProvider>
  );
}

function LayoutContextComponent() {
  const [theme, setTheme] = useState("dark");
  const [roleName, setRoleName] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [userData, setUserData] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [userRegion, setUserRegion] = useState(null);
  const [regionName, setRegionName] = useState("");

  const isUserDataFetchedRef = useRef(false);
  const isRolesFetchedRef = useRef(false);
  const isRegionFetchedRef = useRef(false);
  const isUserLogCreatedRef = useRef(false);

  useEffect(() => {
    keycloak.onReady = () => {
      setInitialized(true);
    };
  }, []);

  const fetchIPAddress = useCallback(async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setIpAddress(response.data.ip);
      console.log("IP Address fetched:", response.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
      setIpAddress("Unknown");
    }
  }, []);

  useEffect(() => {
    fetchIPAddress();
  }, [fetchIPAddress]);

  const getThemeData = useCallback(async () => {
    if (isUserDataFetchedRef.current) return "dark";

    try {
      const userProfile = await getData(getThemeURL);
      console.log("User profile fetched:", userProfile);
      if (userProfile && userProfile.length > 0) {
        const user = userProfile[0];
        setUserData({
          first_name: user.first_name,
          last_name: user.last_name,
          id: user.id,
          phone_number: user.phone_number,
          user_name: user.user_name,
          job_title_id: user.job_title_id,
          agency_id: user.agency_id,
        });
        isUserDataFetchedRef.current = true;
        return user.theme || "dark";
      }
      return "dark";
    } catch (error) {
      console.error("Error fetching theme data:", error);
      return "dark";
    }
  }, []);

  const getUserRoles = useCallback(async () => {
    if (isRolesFetchedRef.current) return;

    try {
      let rolesSanitized = [];
      let roleArr = await getData(findRolesByUser);
      console.log("Roles fetched:", roleArr);
      roleArr.forEach((roleObj) => {
        rolesSanitized.push(roleObj.name);
      });
      rolesSanitized = rolesSanitized.join(",");
      if (rolesSanitized.length > 20) {
        rolesSanitized = rolesSanitized.substring(0, 20) + "...";
      }
      setRoleName(rolesSanitized);
      isRolesFetchedRef.current = true;
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  }, []);

  const getUserRegion = useCallback(async (userId) => {
    if (isRegionFetchedRef.current) return null;

    try {
      const userList = await getData(getUsersFromApp);
      console.log("User list fetched:", userList);
      if (userList && Array.isArray(userList) && userList.length > 0) {
        const user = userList.find((user) => user.id === userId);
        if (user && user.user_region_map && user.user_region_map.length > 0) {
          const regionId = user.user_region_map[0].region_id;
          if (regionId) {
            setUserRegion(regionId);
            isRegionFetchedRef.current = true;
            console.log("User region ID:", regionId);
            return regionId;
          }
        }
      }
      return null;
    } catch (error) {
      console.error("Error fetching user region:", error);
      return null;
    }
  }, []);

  const getRegionList = useCallback(async (regionId) => {
    try {
      const regionList = await getData(regionListURL);
      console.log("Region list fetched:", regionList);
      if (regionList && Array.isArray(regionList)) {
        const matchingRegion = regionList.find(
          (region) => region.parent_region_id === regionId
        );

        if (matchingRegion) {
          setRegionName(matchingRegion.name);
          console.log("Region name:", matchingRegion.name);
          return matchingRegion.name;
        }
      }
      return null;
    } catch (error) {
      console.error("Error fetching region list:", error);
      return null;
    }
  }, []);

  const createUserLogDetails = useCallback(async () => {
    if (!userData || !roleName) {
      console.error("User data or role not available");
      return;
    }

    let inputDetails = {
      name: `${userData.first_name} ${userData.last_name}`,
      userName: userData.user_name,
      phoneNumber: userData.phone_number,
      jobTitle: userData.job_title_id,
      role: roleName,
      agency: userData.agency_id,
      userRegion: regionName,
      activity: "Log In",
      detail: "Web",
      date: new Date().toISOString().split("T")[0],
      time: new Date().toLocaleTimeString("en-US", { hour12: false }),
      userId: userData.id,
      ipAddress: ipAddress || "Unknown",
    };
    console.log("Input details for user log:", inputDetails);
    sessionStorage.setItem("inputDetails",JSON.stringify(inputDetails));
    try {
      const response = await postDataWithBody(createUserLog, inputDetails, {});
      console.log("User log response:", response);
      if (response.status === "success") {
        // toast.success(response.msg, {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        isUserLogCreatedRef.current = true;
      }
      // } else {
      //   toast.error(response.msg, {
      //     position: toast.POSITION.TOP_CENTER,
      //     autoClose: 2000,
      //   });
      // }
    } catch (error) {
      console.error("Error creating user log:", error);
    }
  }, [userData, roleName, regionName, ipAddress]);

  useEffect(() => {
    const initializeUserData = async () => {
      console.log("Initializing user data...");
      try {
        // Fetch theme data and user profile first
        const themeval = await getThemeData();
        console.log("Theme fetched:", themeval);
        setTheme(themeval);
  
        // Check if basic user data is available
        if (userData && userData.id) {
          console.log("User data found, fetching roles and region...");
          // Fetch user roles
          await getUserRoles();
  
          // Fetch user region
          const fetchedUserRegion = await getUserRegion(userData.id);
          console.log("Fetched user region:", fetchedUserRegion);
  
          // Fetch region list using fetchedUserRegion
          if (fetchedUserRegion) {
            const regionNameResult = await getRegionList(fetchedUserRegion);
            console.log("Region name fetched:", regionNameResult);
          }
        } else {
          console.log("User data not found, skipping further initialization.");
        }
      } catch (error) {
        console.error("Initialization error:", error);
      }
    };
  
    if (initialized) {
      console.log("Keycloak initialized. Starting user data initialization...");
      initializeUserData();
    } else {
      console.log("Keycloak not initialized yet.");
    }
  }, [initialized, userData, getThemeData, getUserRoles, getUserRegion, getRegionList]);
  
  useEffect(() => {
    // Ensure user log is created only after regionName is set
    if (userData && roleName && regionName) {
      console.log("All data available. Creating user log...");
      createUserLogDetails();
    } else {
      console.log("Waiting for all data to be available for user log:", {
        userData,
        roleName,
        regionName,
      });
    }
  }, [regionName, userData, roleName, createUserLogDetails]);
  

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <RoleContext.Provider value={{ roleName }}>
        <LayoutContext>
          <AppContext>
            <MapMarkerContext>
              <div id={theme}>
                <BrowserRouter>
                  <Provider store={store}>
                    <App />
                  </Provider>
                </BrowserRouter>
              </div>
            </MapMarkerContext>
          </AppContext>
        </LayoutContext>
      </RoleContext.Provider>
    </ThemeContext.Provider>
  );
}

root.render(<AppComponent />);
