import * as React from "react";
const SvgDangerouscircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={27.5} cy={27.5} r={27.5} fill="#C11515" fillOpacity={0.285} />
    <circle
      cx={28.009}
      cy={28.009}
      r={23.935}
      fill="#C11515"
      fillOpacity={0.272}
    />
    <circle
      cx={28.264}
      cy={28.264}
      r={21.134}
      fill="#C11515"
      fillOpacity={0.246}
    />
    <circle
      cx={27.755}
      cy={27.755}
      r={17.569}
      fill="#C11515"
      fillOpacity={0.396}
    />
    <circle cx={28.009} cy={28.009} r={14.259} fill="#C11515" />
  </svg>
);
export default SvgDangerouscircle;
