import * as React from "react";
const SvgComparisionUp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.836 20.725c-1.87-11.446-12.664-19.21-24.11-17.34C9.278 5.255 1.515 16.05 3.385 27.496c1.87 11.447 12.665 19.21 24.11 17.34 11.447-1.87 19.21-12.665 17.34-24.111Z"
      fill="#EA3A3D"
      fillOpacity={0.2}
    />
    <path
      d="M32.99 25.207a1.562 1.562 0 0 1-3.083.504l-.895-5.482-8.129 11.203a1.575 1.575 0 0 1-2.181.356 1.543 1.543 0 0 1-.353-2.16l8.129-11.202-5.527.903a1.548 1.548 0 1 1-.5-3.055l9.248-1.51.04-.007.034-.006.088-.014.028-.005.01-.002.03-.004.03-.005.035-.006a1.565 1.565 0 0 1 1.096.512l.022.024.001.008.015.017.014.016.001.01.02.025c.15.2.25.434.289.682l1.538 9.198Z"
      fill="#F16A87"
    />
  </svg>
);
export default SvgComparisionUp;
