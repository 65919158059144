import * as React from "react";
const SvgCamera = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.53 23.09h-1.69v-5.915h1.69a.47.47 0 1 0 0-.939h-2.159a.47.47 0 0 0-.47.47v2.159h-7.396v-.285l1.168-.269a.47.47 0 0 0 .352-.563l-.378-1.646 2.287-.526a.469.469 0 0 0 .352-.563l-.884-3.843.275-.063a.47.47 0 0 0 .352-.563l-.968-4.208a.47.47 0 0 0-.562-.352L.364 10.844a.469.469 0 0 0-.352.562 4.773 4.773 0 0 0 2.165 3.007l-.717.165a.47.47 0 0 0-.353.562l.531 2.31a.47.47 0 0 0 .563.351l1.33-.306.45 1.957a.47.47 0 0 0 .563.352l8.508-1.956.379 1.647a.47.47 0 0 0 .563.352l.816-.188v2.397c0 .26.21.47.47.47h3.755c.26 0 .47-.21.47-.47v-.562h7.397v2.065c0 .26.21.47.47.47h2.159a.47.47 0 0 0 0-.94ZM1.06 11.647l20.192-4.643.757 3.293-16.468 3.787a3.824 3.824 0 0 1-2.906-.489 3.82 3.82 0 0 1-1.575-1.948ZM3.32 16.58l-.873.2-.32-1.393.873-.201.206.896.114.498Zm.876-.38-.262-1.139a4.74 4.74 0 0 0 1.816-.063l15.736-3.618.779 3.385L4.79 18.784l-.594-2.583Zm9.77 1.436 5.764-1.325.274 1.19-5.764 1.325-.274-1.19Zm1.782 3.95v-2.144l2.817-.647v2.79h-2.817Zm3.756-1.032v-.751h7.397v.75h-7.397Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCamera;
