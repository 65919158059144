import * as React from "react";
const SvgVoltage = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.422.219 10.696 1.73 6.853.219.578 21.153H7.2L1.402 36.781l14.197-20.933H7.053L16.422.218Z"
      fill="#000"
    />
  </svg>
);
export default SvgVoltage;
