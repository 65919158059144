import * as React from "react";
const SvgSpeaker = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#B1B1B1"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M30.5 14.5h-6a1.5 1.5 0 0 0 0 3h6a1.5 1.5 0 0 0 0-3ZM29.656 22.283l-5.637-2.052a1.5 1.5 0 0 0-1.027 2.819l5.638 2.052a1.5 1.5 0 0 0 1.026-2.819ZM24.018 11.765l5.638-2.052a1.5 1.5 0 1 0-1.026-2.82l-5.638 2.053a1.5 1.5 0 1 0 1.026 2.819ZM4 24h2V8l-2 .001V24ZM0 10.002v11.999c0 1.105.894 2 2 2h1v-16l-1 .001c-1.106 0-2 .894-2 2ZM18.765.154a1.996 1.996 0 0 0-2.179.433L9.43 7.743s-.211.258-.68.258H7v16h1.672c.328 0 .553.053.835.335l7.079 7.079A2 2 0 0 0 20 30V2.001c0-.808-.486-1.539-1.235-1.847Z" />
  </svg>
);
export default SvgSpeaker;
