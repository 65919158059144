import * as yup from "yup";

export const schema = [
  yup.object({
    name: yup
      .string()
      .required("Please provide the role name")  // Ensure name is provided
      .max(50, "Role name cannot exceed 50 characters")  // Limit name length
      .matches(/^[A-Za-z0-9 ]+$/, "Role name can only contain letters, numbers, and spaces"),  // Allow only alphanumeric and spaces

    permission: yup
      .string()
      .required("Please select a permission")  // Ensure permission is selected
      .nullable()  // Allow null value if no permission is selected
      .notOneOf([null, ""], "Permission is required"),  // Ensure permission is not empty or null
  }),
];



export const CreateRoleConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "roleformlabels.name",
        type: "input",
        mandatory: "true"
      },
      {
        name: "permission",
        label: "roleformlabels.permission",
        type: "hier_select",
        mandatory: "true",
        
      },
      {
        name: "description",
        label: "roleformlabels.description",
        type: "text_area"
      }
    ]
  }
];

    