import * as React from "react";
const SvgSearchicon = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3263_3595)">
      <circle cx="4.5" cy="4.5" r="4.5" stroke="#17A3A0" stroke-width="1.5" />
      <path
        d="M8 8L12.5 12.5"
        stroke="#17A3A0"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3263_3595">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSearchicon;
