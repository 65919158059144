import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "../OpenCloseChart/OpenCloseChart.css";

export default function HealthCampChart() {
  return (
    <div className="card">
      <div className="card-body">
        <div>
          <span className="card-title">Open vs Closed</span>
          <span className="float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16">
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </span>
        </div>
        <div className="bar-containner">
          <div>
            <div className="d-flex justify-content-between mt-3">
              <div className="bar-label">Bleaching Powder</div>
              <div>2/10</div>
            </div>
            <ProgressBar
              completed={2}
              maxCompleted={10}
              bgColor="#B981F6"
              isLabelVisible={false}
              height="12px"
            />
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div  className="bar-label">Halogen Tablets</div>
              <div>2/6</div>
            </div>
            <ProgressBar
              completed={2}
              maxCompleted={6}
              bgColor="#E28771"
              isLabelVisible={false}
              height="12px"
            />
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="bar-label">Number of People Treated</div>
              <div>6/8</div>
            </div>
            <ProgressBar
              completed={6}
              maxCompleted={8}
              isLabelVisible={false}
              bgColor="#D252AA"
              height="12px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
