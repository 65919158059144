// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../Assets/Icons/datepicker.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".datepicker__label {\r\n  color: #ffff;\r\n margin-bottom: 0.2rem;\r\n}\r\n\r\n::-webkit-calendar-picker-indicator {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n.date__container .form__input {\r\n  background-color: var(--secondary-color);\r\n  color: var(--white-font-color);\r\n}\r\n.date__container .form-control:focus {\r\n  background-color: var(--secondary-color);\r\n  color: var(--white-font-color);\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Pages/DMS/Common/Form/DatePicker/DatePicker.css"],"names":[],"mappings":"AAAA;EACE,YAAY;CACb,qBAAqB;AACtB;;AAEA;EACE,yDAAiE;AACnE;AACA;EACE,wCAAwC;EACxC,8BAA8B;AAChC;AACA;EACE,wCAAwC;EACxC,8BAA8B;AAChC","sourcesContent":[".datepicker__label {\r\n  color: #ffff;\r\n margin-bottom: 0.2rem;\r\n}\r\n\r\n::-webkit-calendar-picker-indicator {\r\n  background-image: url(../../../../../Assets/Icons/datepicker.svg);\r\n}\r\n.date__container .form__input {\r\n  background-color: var(--secondary-color);\r\n  color: var(--white-font-color);\r\n}\r\n.date__container .form-control:focus {\r\n  background-color: var(--secondary-color);\r\n  color: var(--white-font-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
