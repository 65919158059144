import * as React from "react";
const SvgVehicleSideview = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#B1B1B1"
    viewBox="0 0 35 21"
    {...props}
  >
    <path d="M7.52 13.424a3.297 3.297 0 1 0-.002 6.593 3.297 3.297 0 0 0 .001-6.593Zm0 4.73a1.433 1.433 0 1 1 0-2.866 1.433 1.433 0 0 1 0 2.866ZM24.907 13.424a3.297 3.297 0 1 0-.001 6.593 3.297 3.297 0 0 0 0-6.593Zm0 4.73a1.433 1.433 0 1 1 0-2.866 1.433 1.433 0 0 1 0 2.866ZM.985 9.508h15.202a.988.988 0 0 0 .985-.985V2.661a.988.988 0 0 0-.985-.985H.985A.988.988 0 0 0 0 2.66v5.862c0 .542.443.985.985.985Z" />
    <path d="M31.368 8.207c-1.21-.314-1.901-.512-2.376-1.429l-1.587-3.214c-.475-.917-1.709-1.667-2.74-1.667h-3.056V.985A.988.988 0 0 0 20.624 0h-1.851a.988.988 0 0 0-.985.985V9.55a1.202 1.202 0 0 1-1.075.672H5.02c-.028 0-.063.001-.104.004H2.995v-.004h-.8c-.048-.007-1.28-.002-1.28-.002-.543 0-.915.434-.915.976V15.93c0 .542.443.985.985.985H3.228a4.301 4.301 0 0 1 4.292-4.495 4.301 4.301 0 0 1 4.291 4.495h8.49c.107 0 .211-.01.313-.027a4.301 4.301 0 0 1 4.292-4.468 4.301 4.301 0 0 1 4.293 4.495h3.923A1.883 1.883 0 0 0 35 15.04v-3.567c0-3.004-2.629-3.004-3.632-3.265ZM21.61 8.04V3.202h2.387c.919 0 2.016.668 2.438 1.484l1.413 2.86c.103.2.219.36.349.494H21.61Z" />
  </svg>
);
export default SvgVehicleSideview;
