import axios from "axios";
import { serviceBaseURL,gisserviceBaseURL} from "./EndPoints";
import keycloak from "../Keycloak";
export async function getData(endPoint) {
  try {
    let url = '/'+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+endPoint;
    const response = await axios.get(url,{
      withCredentials: true ,
      headers: {
          Authorization: 'Bearer '+localStorage.getItem("auth_token"),
      }
     });
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function getDataFrGIS(endPoint, body,header) {
  try {
    const response = await axios.get(gisserviceBaseURL + endPoint,{headers:header})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}


export async function postDataWithBodyFrGIS(endPoint, body,header) {
  try {
    const response = await axios.post(gisserviceBaseURL + endPoint, body,{headers:header})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function postDataWithBody(endPoint, body,header) {
  header["Authorization"] = 'Bearer '+localStorage.getItem("auth_token");
  try {

    let url = '/'+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+endPoint;
    const response = await axios.post(url, body,{headers:header})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function putDataWithBody(endPoint, body,header) {
  try {
    header["Authorization"] = 'Bearer '+localStorage.getItem("auth_token");
    let url = '/'+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+endPoint;
    const response = await axios.put(url, body,{headers:header})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function deleteData(endPoint, id) {
  try {
    const response = await axios.delete(endPoint, {
      withCredentials: true ,
      headers: {
          Authorization: 'Bearer '+localStorage.getItem("auth_token"),
      }
     });
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function deleteDataById(baseURL, id) {
  try {
    const endPoint = `${baseURL}/${id}`;
    let url = '/'+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+endPoint;
    const response = await axios.delete(url, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });

    if (response.status === 201) {
      return response.data;
    } else {
      console.log(response);
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}


// export async function deleteData(endPoint, id) {
//   try {
//     const url = `${baseUrl}${endPoint}/${id}`;
//     const response = await axios.delete(url);
//     if (response.status === 200) {
//       return response.data;
//     } else {
//       console.log(response);
//     }
//   } catch (error) {
//     console.log(error);
//     return [];
//   }
// }

export async function editData(endPoint, id) {
  try {
    const response = await axios.put(endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fileUploadService(endPoint, formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const response = await axios.post(
      serviceBaseURL + endPoint,
      formData,
      config
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function getWFSServiceData(endPoint, cqlquery) {
  try {
    const gisserverurl = process.env.REACT_APP_GEO_SERVER;
    let wfsurl =gisserverurl+endPoint+"&CQL_FILTER=" + cqlquery;
    console.log(wfsurl);
    const response = await axios.get(wfsurl,{headers:{}})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function getWFSLayer(endPoint) {
  try {
    console.log(endPoint);
    const response = await axios.get(endPoint,{headers:{}})  ;
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } 
    else {
      console.log(response);
    }
    return response.data;
  } 
  catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export const getImageByFacility = async (facilityId,typeid,opendate) => {
  try {
    const headers = {
      Authorization: 'Bearer ' + localStorage.getItem("auth_token")
    };

    const response = await axios.get(
      `/dms-service/facility_report/getImageByFacility?facilityId=${facilityId}&facilityTypeId=${typeid}&openDate=${opendate}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch image data:', error);
  }
};

