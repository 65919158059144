import React, { useEffect, useState } from "react";
import "./WeatherDataTable.css";
import axios from "axios";
import ToggleViewBmsk from "./ToggleView/ToggleViewBmsk";
import DoughnutChart from "./DoughnutChart";
import HalfDoughnutChart from "./HalfDoughnutChart";
import HorizontalBarChart from "./HorizontalBarChart";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getBMSKData } from "../../../Services/EndPoints";
import { getData } from "../../../Services/Services";
import { Expand } from "../../../Components/IconComponents";

const WeatherDataTable = ({
  affectedDistrictData,
  affectedBlockData,
  affectedNagarNigamData,
  affectedPanchayatData,
  fromDate,
  toDate,
}) => {
  const [weatherApiData, setWeatherApiData] = useState([]);
  const [activeTabBmsk, setActiveTabBmsk] = useState("Charts"); // Set default to "Charts"
  const [expandedRows, setExpandedRows] = useState([]);
  const { t } = useTranslation();

 

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };

  const formatDates = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const todayDate = formatDate(new Date());
  const todayDates = formatDates(new Date());

  const toggleRowExpand = (index) => {
    setExpandedRows((prev) => (prev === index ? null : index)); // Allow only one expanded row
  };

  console.log(todayDate, "todaydate");

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await getData(
          getBMSKData.replace("FROMDATE", todayDate)
        );
        setWeatherApiData(response.path);
        console.log(response.path);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeatherData();
  }, [todayDate]);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 6,
      height: 4,
      id: 7,
      component: (
        <DoughnutChart
          title={t("flooddisaster.doughnutchart1")}
          dataval={affectedDistrictData}
          startDateStr={moment(fromDate).format("YYYY-MM-DD")}
          endDateStr={moment(toDate).format("YYYY-MM-DD")}
          partial={true}
          showViewIcon={true}
        />
      ),
    },
    {
      x: 6,
      y: 0,
      width: 6,
      height: 4,
      id: 8,
      component: (
        <HalfDoughnutChart
          startDateStr={moment(fromDate).format("YYYY-MM-DD")}
          endDateStr={moment(toDate).format("YYYY-MM-DD")}
          title={t("flooddisaster.doughnutchart2")}
          dataval={affectedBlockData}
          partial={true}
          showViewIcon={true}
        />
      ),
    },
    {
      x: 0,
      y: 4,
      width: 6,
      height: 5,
      id: 10,
      component: (
        <HorizontalBarChart
          title={t("flooddisaster.doughnutchart3")}
          dataval={affectedNagarNigamData}
          showAffectedCount={true}
        />
      ),
    },
    {
      x: 6,
      y: 4,
      width: 6,
      height: 5,
      id: 12,
      component: (
        <HorizontalBarChart
          title={t("flooddisaster.doughnutchart4")}
          dataval={affectedPanchayatData}
          showAffectedCount={true}
        />
      ),
    },
  ];

  return (
    <div>
      <div
        className={
          activeTabBmsk === "Charts"
            ? "header-container"
            : "header-container-chart"
        }
      >
        {activeTabBmsk === "BMSK" ? (
          <span className="p-data">
            Data received at{" "}
            {new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            on {todayDates}
          </span>
        ) : null}
        <div className="toggle-container">
          <ToggleViewBmsk
            className={
              activeTabBmsk === "Charts" ? "button-Charts" : "button-BMSK"
            }
            setActiveTabBmsk={setActiveTabBmsk}
            activeTabBmsk={activeTabBmsk}
          />
        </div>
      </div>

      <div className="charts-grid-wrapper">
        {activeTabBmsk === "Charts" ? (
          <div className="grid-stack grid-stack-instance-1 mt-1">
            {layoutData.map((gridItem) => (
              <div
                className="grid-stack-item"
                gs-x={gridItem.x}
                gs-y={gridItem.y}
                gs-w={gridItem.width}
                gs-h={gridItem.height}
                key={gridItem.id}
              >
                <div className="grid-stack-item-content">
                  {gridItem.component}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>S NO</th>
                  <th>District</th>
                  <th>Rainfall (mm)</th>
                  <th>Temperature (°C)</th>
                  <th>Humidity (%)</th>
                  <th>Wind Speed (m/s)</th>
                  {/* <th>Wind Direction (°)</th> */}
                </tr>
              </thead>
              <tbody>
  {Array.from(new Set(weatherApiData?.map((row) => row.DISTRICT))) // Get unique districts
    .map((district, index) => {
      // Filter rows for the current district
      const districtData = weatherApiData?.filter(
        (row) => row.DISTRICT === district
      );

      // Calculate min and max values
      const minMaxValues = districtData.reduce(
        (acc, row) => {
          acc.RAINFALL.min = Math.min(
            acc.RAINFALL.min,
            row.RAINFALL || Infinity
          );
          acc.RAINFALL.max = Math.max(
            acc.RAINFALL.max,
            row.RAINFALL || -Infinity
          );
          acc.TEMP_MIN.min = Math.min(
            acc.TEMP_MIN.min,
            row.TEMP_MIN || Infinity
          );
          acc.TEMP_MAX.max = Math.max(
            acc.TEMP_MAX.max,
            row.TEMP_MAX || -Infinity
          );
          acc.HUMIDITY.min = Math.min(
            acc.HUMIDITY.min,
            row.RH_MIN || Infinity
          );
          acc.HUMIDITY.max = Math.max(
            acc.HUMIDITY.max,
            row.RH_AVG || -Infinity
          );
          acc.WIND_SPEED.min = Math.min(
            acc.WIND_SPEED.min,
            row.WS_MAX || Infinity
          );
          acc.WIND_SPEED.max = Math.max(
            acc.WIND_SPEED.max,
            row.WS_MAX || -Infinity
          );
          return acc;
        },
        {
          RAINFALL: { min: Infinity, max: -Infinity },
          TEMP_MIN: { min: Infinity, max: -Infinity },
          TEMP_MAX: { min: Infinity, max: -Infinity },
          HUMIDITY: { min: Infinity, max: -Infinity },
          WIND_SPEED: { min: Infinity, max: -Infinity },
        }
      );

      return (
        <React.Fragment key={index}>
          {/* Main Row for the District */}
          <tr
            key={index}
            className={
              index % 2 === 0 ? "dark-blue-row" : "light-blue-row"
            }
          >
            <td>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "1.2em",
                  color: expandedRows === index ? "white" : "white",
                }}
                onClick={() => toggleRowExpand(index)}
              >
                {expandedRows === index ? "-" : "+"}
              </button>
            </td>
            <td>{index + 1}</td>
            <td>{district}</td>
            <td>{`${minMaxValues.RAINFALL.min} - ${minMaxValues.RAINFALL.max}`}</td>
            <td>{`${minMaxValues.TEMP_MIN.min} - ${minMaxValues.TEMP_MAX.max}`}</td>
            <td>{`${minMaxValues.HUMIDITY.min} - ${minMaxValues.HUMIDITY.max}`}</td>
            <td>{`${minMaxValues.WIND_SPEED.min} - ${minMaxValues.WIND_SPEED.max}`}</td>
          </tr>
          {/* Expanded Rows for All Blocks in the District */}
          {expandedRows === index && (
            <tr>
              <td colSpan="7">
                <div className="expanded-content">
                  <div className="block-details-container">
                    <div
                      style={{
                        textAlign: "left",
                        padding: "10px",
                      }}
                    >
                      <strong>
                        Block Details for {district}:
                      </strong>
                    </div>
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th>Block</th>
                          <th>Rainfall (mm)</th>
                          <th>Temperature (°C)</th>
                          <th>Humidity (%)</th>
                          <th>Wind Speed (m/s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {districtData.map((districtRow, i) => (
                          <tr
                            key={i}
                            className={
                              i % 2 === 0
                                ? "dark-blue-row"
                                : "light-blue-row"
                            }
                          >
                            <td>{districtRow.BLOCK || "NA"}</td>
                            <td>{districtRow.RAINFALL}</td>
                            <td>{`${
                              districtRow.TEMP_MIN || "NA"
                            } - ${
                              districtRow.TEMP_MAX || "NA"
                            }`}</td>
                            <td>{`${districtRow.RH_MIN || "NA"} - ${
                              districtRow.RH_AVG || "NA"
                            }`}</td>
                            <td>{districtRow.WS_MAX || "NA"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    })}
</tbody>

            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeatherDataTable;
