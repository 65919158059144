import * as React from "react";
const SvgOrangerectangle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#F4694C" d="M0 0h9v9H0z" />
  </svg>
);
export default SvgOrangerectangle;
