import * as yup from "yup";

const data = {
  website: "example.com",
};

try {
  schema.validateSync(data);
  // If validation passes, form submission takes place.
} catch (error) {
  // If validation fails,error message will be appearing.
  console.error(error.errors);
}

export const schema = [
  yup.object({
    name: yup.string().required("Please provide the agency name"),
    description: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Description must be at most 200 characters"),
      phone: yup
      .string()
      .required("Please provide the phone number")
      .matches(
        /^(?:\+91|0)?[6789]\d{9}$/,
        "Please provide a valid phone number"
      )
      .max(15, "Phone number must be at most 15 digits"),
  }),
];

export const AgencyFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      { name: "name", label: "agencyformlabels.agencyname",maxLength:60, type: "input", mandatory: "true" },
      {
        name: "agency_type_id",
        label: "agencyformlabels.agencytypeid",
        type: "select",
      },
      {
        name: "phone",
        label: "agencyformlabels.phone",
        type: "input",
        mandatory: "true",
      },
      { name: "website", label: "agencyformlabels.website", type: "input" },
      {
        name: "description",
        label: "agencyformlabels.description",
        type: "text_area",
      },
    ],
  },
];
export const agencyTableData = [
  {
    name: "OSDMA/SRC",
    agencytype: "Revenue Disaster Management Department",
    hierarchylevel: "State",
    phone1: "9090908978",
    phone2: "9090908978",
    email: "revenue@gmail.com",
  },
  {
    name: "Additional District Megistrate,anugul",
    agencytype: "Police Department",
    hierarchylevel: "Block/Sub Division",
    phone1: "9090908977",
    phone2: "9090908978",
    email: "Policedept@gmail.com",
  },
  {
    name: "ADH/Sonepur",
    agencytype: "Agriculture & Farmers Empowerment Department",
    hierarchylevel: "Block/Sub Division",
    phone1: "9090908979",
    phone2: "9090908978",
    email: "agridept@gmail.com",
  },
];

export const columnsForExport = [
  { label: "Name", key: "name" },
  { label: "Agency Type", key: "agency_type" },
  { label: "Description", key: "description" },
  {label: "Created On", key: "created_on"},
];
