import * as React from "react";
const SvgList = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.937 5.003a1.99 1.99 0 0 0 0-3.978A1.99 1.99 0 0 0-.05 3.012c0 1.094.893 1.991 1.987 1.991Zm0-2.977c.546 0 .987.444.987.986A.99.99 0 0 1 1.937 4a.99.99 0 0 1-.986-.987.99.99 0 0 1 .986-.986ZM1.937 10.987A1.99 1.99 0 0 0 3.924 9a1.987 1.987 0 1 0-1.987 1.987Zm0-2.973c.546 0 .987.444.987.986a.99.99 0 0 1-.987.987.988.988 0 0 1 0-1.973ZM1.937 16.975a1.99 1.99 0 0 0 1.987-1.987A1.99 1.99 0 0 0 1.937 13 1.992 1.992 0 0 0-.05 14.988c0 1.093.894 1.987 1.987 1.987Zm0-2.977a.988.988 0 0 1 0 1.973.99.99 0 0 1-.986-.987.99.99 0 0 1 .986-.986ZM6.466 3.514h11.082a.502.502 0 1 0 0-1.004H6.466a.502.502 0 1 0 0 1.004ZM6.466 9.502h11.082a.502.502 0 1 0 0-1.004H6.466a.502.502 0 1 0 0 1.004ZM6.466 15.486h11.082a.502.502 0 1 0 0-1.004H6.466a.502.502 0 1 0 0 1.005Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
    />
  </svg>
);
export default SvgList;
