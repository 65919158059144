import * as React from "react";
const SvgArrowup = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 0 0 5h3.333v5h3.334V5H10L5 0Z" fill="#03DE73" />
  </svg>
);
export default SvgArrowup;
