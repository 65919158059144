import * as React from "react";
const SvgZoomIn = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.193 13.607c3.29 0 5.958-2.565 5.958-5.73 0-3.164-2.668-5.729-5.958-5.729-3.291 0-5.959 2.565-5.959 5.73 0 3.164 2.668 5.729 5.959 5.729ZM15.64 15.039l-3.24-3.116M8.192 5.73v4.296M5.958 7.878h4.469"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgZoomIn;
