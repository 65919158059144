import * as React from "react";
const SvgClock = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 16.083a7.083 7.083 0 1 0 0-14.167 7.083 7.083 0 0 0 0 14.167Z"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 4.75V9l2.833 1.417"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgClock;
