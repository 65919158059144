import * as yup from "yup";
const FileSize = 15000000;
const allowedFormats = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const masterResourceTableData = [
  {
    activity: "abc",
    name: "xyz",
    kind: "bbb",
    unit: "12",
  },
  {
    activity: "abc",
    name: "xyz",
    kind: "bbb",
    unit: "12",
  },
  {
    activity: "abc",
    name: "xyz",
    kind: "bbb",
    unit: "12",
  },
  {
    activity: "abc",
    name: "xyz",
    kind: "bbb",
    unit: "12",
  },
  {
    activity: "abc",
    name: "xyz",
    kind: "bbb",
    unit: "12",
  },
];

export const MasterResourceFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "activity",
        label: "masterresourceformlabels.activity",
        mandatory: "true",
        type: "select",
      },
      {
        name: "category",
        label: "masterresourceformlabels.category",
        type: "select",
        options: [],
        mandatory: "true",
      },
      {
        name: "name",
        label: "masterresourceformlabels.resourcename",
        type: "input",
        maxLength: 30,
        mandatory: "true",
      },
      {
        name: "unit",
        label: "masterresourceformlabels.unit",
        type: "select",
        options: [
          { value: "Nos", label: "Nos" },
          { value: "Bags", label: "Bags" },
          { value: "Box", label: "Box" },
          { value: "Kg", label: "Kg" },
          { value: "Liter", label: "Liter" },
          { value: "Meter", label: "Meter" },
          { value: "Metric", label: "Metric" },
          { value: "Nos", label: "Nos" },
          { value: "Pair", label: "Pair" },
          { value: "Persons", label: "Persons" },
          { value: "Sets", label: "Sets" },
          { value: "Tablets", label: "Tablets" },
          { value: "Team", label: "Team" },
          { value: "Vials", label: "Vials" },
        ],
        //type: "input",
        mandatory: "true",
      },
      {
        name: "resource_image_file",
        label: "masterresourceformlabels.resourceimage",
        type: "file_upload_MR",
      },
    ],
  },
];

export const masterresourceschema = [
  yup.object({
    activity: yup.string().required("Please select option"),
    category: yup.string().required("Please select option"),
    name: yup.string().required("Please enter resource name"),
    unit: yup.string().required("Please select option"),
  }),
];

export const masterResourcecolumnsForExport = [
  { label: "Activity", key: "activity" },
  { label: "Name", key: "name" },
  {label: "Created On", key: "created_on"},
  {label: "Updated On", key: "updated_on"},
  {label: "Image", key: "resource_image_name"},
  { label: "Category", key: "category" },
  { label: "Unit", key: "unit" },
];
