import * as React from "react";
const SvgWeighmentSlip = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 27 32"
    {...props}
  >
    <path
      fill="#B1B1B1"
      d="M26.397 30.46c.001.837-.557 1.516-1.247 1.517L1.279 32c-.69 0-1.25-.677-1.25-1.514L0 1.533C0 .696.558.018 1.248.017L18.81 0l7.563 7.095.023 23.366Z"
    />
    <path
      fill="#C4BCB1"
      d="m26.397 7.278-6.374.007c-.69 0-1.25-.677-1.25-1.514L18.767.184l7.63 7.094Z"
    />
    <path
      fill="#1C2038"
      d="M19.907 12.12a.76.76 0 0 1-.76.76H6.607a.76.76 0 1 1 0-1.52h12.54a.76.76 0 0 1 .76.76Zm0 5.7a.76.76 0 0 1-.76.76H6.607a.76.76 0 0 1 0-1.52h12.54a.76.76 0 0 1 .76.76Zm0 6.079a.76.76 0 0 1-.76.76H6.607a.76.76 0 0 1 0-1.52h12.54a.76.76 0 0 1 .76.76Z"
    />
  </svg>
);
export default SvgWeighmentSlip;
