import * as React from "react";
const SvgStreetlight1 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.774 2.84C21.527 1.236 20.094 0 18.367 0c-1.195 0-2.249.592-2.867 1.488A3.475 3.475 0 0 0 12.633 0c-1.727 0-3.16 1.235-3.407 2.84-.964.237-1.68 1.083-1.68 2.088 0 1.187.999 2.153 2.226 2.153 1.226 0 2.224-.966 2.224-2.153 0-.978-.676-1.804-1.6-2.066.224-.996 1.142-1.744 2.237-1.744 1.262 0 2.29.994 2.29 2.216v13.861h-.158a.569.569 0 0 0-.578.547l-.249 11.14h-2.712c-.32 0-.578.25-.578.559 0 .309.258.559.578.559h8.549c.319 0 .577-.25.577-.559a.568.568 0 0 0-.577-.559h-2.713l-.25-11.14a.569.569 0 0 0-.577-.547h-.157V3.334c0-1.222 1.027-2.216 2.29-2.216 1.094 0 2.012.748 2.236 1.744-.924.261-1.6 1.088-1.6 2.066 0 1.187.998 2.153 2.224 2.153 1.227 0 2.225-.966 2.225-2.153 0-1.005-.715-1.851-1.679-2.087ZM10.841 4.929c0 .57-.48 1.035-1.07 1.035-.59 0-1.069-.465-1.069-1.035 0-.57.48-1.035 1.07-1.035.59 0 1.07.464 1.07 1.035Zm4.253 23.954.236-10.569h.34l.236 10.569h-.812Zm6.134-22.92c-.59 0-1.07-.463-1.07-1.034 0-.57.48-1.035 1.07-1.035.59 0 1.07.464 1.07 1.035 0 .57-.48 1.035-1.07 1.035Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgStreetlight1;
