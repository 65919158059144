import * as React from "react";
const SvgCloud = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 6.667h-.84A5.333 5.333 0 1 0 6 13.333h6a3.334 3.334 0 0 0 0-6.666Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCloud;
