import * as React from "react";
const SvgPtz4 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1.286 7.981 9.048 2.478-1.67-9.231L1.285 7.98Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.847 7.67.238 2.175a.3.3 0 0 1-.355.327l-2.148-.416"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz4;
