import * as React from "react";
const SvgPlay = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.542 1.125 10.458 7.5.542 13.875V1.125Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPlay;
