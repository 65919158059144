import * as React from "react";
const SvgPtz1 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.468 13.248 2.118-9.138-9.158 2.034 7.04 7.104Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7.82 4.707 2.163-.324a.3.3 0 0 1 .341.342l-.33 2.163"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz1;
