import React from "react";
import "./TextareaInput.css";
import { useTranslation } from "react-i18next";

const TextareaInput = ({
  name,
  errors,
  register,
  label,
  type,
  mandatory,
  value,
  disabled = false,
}) => {
  const { onChange, ...registerParams } = register(name);
  const { t } = useTranslation();
  return (
    <div className="input_text-form">
      <label>
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <textarea

        autoComplete="off"
        className={`form_text_area form-control form-control-sm ${errors[name] ? "error__border--color" : "form__input--area"
          }`}
        type={type}
        onChange={(event) => {
          onChange(event);
        }}
        {...registerParams}
        disabled={disabled}
      />
      <div className="error__message">{errors[name]?.message}</div>
    </div>
  );
};

export default TextareaInput;
