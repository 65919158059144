import * as React from "react";
const SvgSmartphone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.542 1.417H5.459c-.783 0-1.417.634-1.417 1.416v11.334c0 .782.634 1.416 1.417 1.416h7.083c.782 0 1.417-.634 1.417-1.417V2.833c0-.782-.635-1.417-1.417-1.417ZM9 12.75h.008"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSmartphone;
