import * as React from "react";
const SvgWhitedot = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5} cy={5} r={5} fill="#CFD9DF" />
  </svg>
);
export default SvgWhitedot;
