import * as React from "react";
const SvgPtz7 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4.89 13.881 7.977-4.936L4.97 3.88 4.89 13.88Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.493 7.403 1.754 1.307a.3.3 0 0 1-.002.483l-1.768 1.288"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz7;
