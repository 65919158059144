import * as React from "react";
const SvgCrosshair = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#crosshair_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 12.833A5.833 5.833 0 1 0 7 1.167a5.833 5.833 0 0 0 0 11.666ZM12.833 7H10.5M3.5 7H1.167M7 3.5V1.167M7 12.833V10.5" />
    </g>
    <defs>
      <clipPath id="crosshair_svg__a">
        <path fill="currentColor" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCrosshair;
