import * as React from "react";
const SvgVehicle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="#FFA100"
      d="M25.302 0H4.962v1.507h20.34V0ZM19.077 19.601v3.15h7.29V2.637H3.73V22.75h7.293V19.6l8.055.001Zm2.523.146a2.08 2.08 0 1 1 0-4.164c1.148 0 2.084.932 2.084 2.082a2.085 2.085 0 0 1-2.084 2.082Zm-6.129-14.9h9.552v5.713H15.47V4.848Zm-10.393 0h9.548v5.713H5.078V4.848Zm3.695 14.738a2.083 2.083 0 1 1-.002-4.164 2.083 2.083 0 0 1 .002 4.164ZM19.077 23.908v1.997h-8.055v-1.997H2.41v2.577h25.278v-2.577h-8.61Z"
    />
    <path
      fill="#FFA100"
      d="M19.077 22.758h-8.055v1.157h8.055v-1.157ZM7.375 26.486H3.729v3.385h3.646v-3.385ZM26.369 26.486h-3.644v3.385h3.644v-3.385ZM30 7.707h-3.03v5.628H30V7.707ZM3.03 7.707H0v5.628h3.03V7.707Z"
    />
  </svg>
);
export default SvgVehicle;
