import * as React from "react";
const SvgNotificationIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 29c-.699 0-1.399-.3-1.897-.8-.5-.5-.801-1.2-.801-1.897h5.5c0 .7-.3 1.397-.8 1.897-.603.5-1.303.8-2.002.8ZM25 24.9H0v-1.503l2.901-2.899v-8c0-2.198.701-4.198 2.101-5.897 1.3-1.7 3.199-2.903 5.3-3.3v-.998c0-.603.198-1.1.7-1.6.899-.9 2.3-.9 3.101 0 .399.4.699.998.699 1.6v.998c2.101.5 4 1.6 5.301 3.3a9.269 9.269 0 0 1 2.099 5.897v8l2.903 2.9v1.501H25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgNotificationIcon;
