import * as React from "react";
const SvgIresponseFireLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 78 51"
    {...props}
  >
    <mask
      id="IresponseFireLogo_svg__a"
      width={78}
      height={51}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="currentColor" d="M0 .672h77.119v50H0v-50Z" />
    </mask>
    <g mask="url(#IresponseFireLogo_svg__a)">
      <path
        fill="currentColor"
        d="M60.553 49.303c8.391 0 15.215-6.285 15.215-14.013 0-6.537-4.824-12.147-11.728-13.635l-.547-.119.013-.653c.005-.049.005-.084.005-.124 0-4.974-4.039-9.022-9.009-9.022a9.02 9.02 0 0 0-2.25.287l-.509.133-.251-.46A18.723 18.723 0 0 0 35.13 2.046c-10.232 0-18.618 8.333-18.688 18.582l-.005.64-.64.03c-8.1.371-14.446 6.52-14.446 13.993 0 7.728 6.828 14.014 15.219 14.014h3.155v-5.725c0-.393.226-.83.548-1.055l5.27-3.677a1.13 1.13 0 0 1 .644-.19c.243 0 .473.067.65.19l4.466 3.116V27.777c0-.565.459-1.024 1.02-1.024h13.034c.565 0 1.024.46 1.024 1.024v5.83H55a.97.97 0 0 1 .962.972v14.724h4.59Zm-5.95 1.356v-1.355h.01V34.962H45.03v-6.86H32.654v16.46l-6.467-4.52-5.11 3.571v7.045H16.57C7.433 50.659 0 43.764 0 35.289c0-3.893 1.571-7.605 4.418-10.456 2.803-2.798 6.59-4.515 10.686-4.846A19.977 19.977 0 0 1 21.006 6.52 19.906 19.906 0 0 1 35.13.69c7.084 0 13.696 3.79 17.289 9.909a10.3 10.3 0 0 1 2.083-.212c5.58 0 10.192 4.502 10.36 10.068 3.358.838 6.404 2.683 8.598 5.204 2.357 2.714 3.659 6.135 3.659 9.63 0 8.475-7.433 15.37-16.566 15.37h-5.95Z"
      />
    </g>
    <path
      fill="currentColor"
      d="M37.34 32.227c0 .141-.11.251-.252.251h-1.532a.249.249 0 0 1-.251-.251v-1.342c0-.141.11-.256.251-.256h1.532c.141 0 .252.115.252.256v1.342ZM37.381 39.553a.255.255 0 0 1-.252.256h-1.531a.256.256 0 0 1-.256-.256v-1.341c0-.142.115-.252.256-.252h1.531c.137 0 .252.11.252.252v1.341ZM37.379 41.875v1.341c0 .142-.11.256-.252.256h-1.532a.252.252 0 0 1-.251-.256v-1.341c0-.142.11-.252.251-.252h1.532c.141 0 .252.11.252.252ZM42.336 32.227a.252.252 0 0 1-.256.251h-1.532a.251.251 0 0 1-.251-.251v-1.342c0-.141.115-.256.252-.256h1.531c.141 0 .256.115.256.256v1.342ZM42.377 35.89a.253.253 0 0 1-.256.251H40.59a.249.249 0 0 1-.251-.251v-1.342c0-.141.11-.256.252-.256h1.531c.141 0 .256.115.256.256v1.342ZM42.377 39.553a.256.256 0 0 1-.256.256H40.59a.252.252 0 0 1-.251-.256v-1.341c0-.142.11-.252.252-.252h1.531c.141 0 .256.11.256.252v1.341ZM42.377 41.875v1.341a.256.256 0 0 1-.256.256H40.59a.255.255 0 0 1-.251-.256v-1.341c0-.142.115-.252.252-.252h1.531c.141 0 .256.11.256.252ZM53.063 37.902a.256.256 0 0 1-.256.256h-1.532a.253.253 0 0 1-.252-.256V36.56c0-.141.11-.251.252-.251h1.532c.14 0 .256.11.256.251v1.342ZM53.063 41.196a.256.256 0 0 1-.256.256h-1.532a.252.252 0 0 1-.252-.256v-1.337c0-.142.11-.257.252-.257h1.532c.14 0 .256.115.256.257v1.337ZM53.063 44.489a.256.256 0 0 1-.256.256h-1.532a.253.253 0 0 1-.252-.256v-1.337c0-.142.11-.257.252-.257h1.532c.14 0 .256.115.256.256v1.338ZM27.463 45.424a.316.316 0 0 1-.318.318h-1.911a.317.317 0 0 1-.318-.318v-1.678a.32.32 0 0 1 .318-.317h1.911a.32.32 0 0 1 .318.317v1.678ZM37.381 35.89a.252.252 0 0 1-.252.251h-1.531a.253.253 0 0 1-.256-.251v-1.342c0-.141.115-.256.256-.256h1.531c.137 0 .252.115.252.256v1.342Z"
    />
    <mask
      id="IresponseFireLogo_svg__b"
      width={16}
      height={22}
      x={31}
      y={7}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="currentColor" d="M31.342 7.68h14.69v20.34h-14.69V7.68Z" />
    </mask>
    <g mask="url(#IresponseFireLogo_svg__b)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M40.616 7.68s-.76 3.316 1.143 6.277c1.907 2.962 8.417 8.797-.79 14.226 0 0 1.95-2.772.967-4.666-.98-1.889-3.094-3.297-2.741-6.258 0 0-2.785 1.156-1.554 6.409 0 0-1.571-.574-2.23-2.119 0 0-.974 3.032.813 6.581 0 0-9.459-4.511-1.832-13.268 0 0 .16 2.86 1.29 3.65 0 0-1.864-7.742 4.934-10.831Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgIresponseFireLogo;
