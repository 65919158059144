import * as React from "react";
const SvgSlash = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#slash_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 9.167A4.167 4.167 0 1 0 5 .833a4.167 4.167 0 0 0 0 8.334ZM2.054 2.054l5.892 5.892" />
    </g>
    <defs>
      <clipPath id="slash_svg__a">
        <path fill="currentColor" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSlash;
