import * as React from "react";
const SvgPoositionSensor = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#B1B1B1"
    viewBox="0 0 36 24"
    {...props}
  >
    <path d="M28.415 11.826H18.403V5.581a2.81 2.81 0 0 0 2.392-2.775A2.81 2.81 0 0 0 17.99 0a2.81 2.81 0 0 0-2.806 2.806 2.81 2.81 0 0 0 2.12 2.72v6.3h-9.74L0 21.798v1.32h36v-1.32l-7.585-9.971Zm-4.38 1.1h3.835l1.729 2.273h-6.331l.767-2.273Zm-15.925 0h5.39l-.865 1.173H7.22l.89-1.173Zm-6.896 9.092 1.724-2.273h6.685l-1.53 2.273h-6.88Zm19.162 0h-7.71l.587-1.173h7.416l-.293 1.173Zm2.877 0H21.51l.568-2.273h-9.505l-1.137 2.273H9.418l2.272-3.373H3.772l.945-1.246h.855v-1.1h-.02l.834-1.1h6.805l1.675-2.273h2.438v2.863l-.702-.468-.61.915 1.006.67-1.006.671.61.915 1.387-.924 1.387.924.61-.915-1.005-.67 1.005-.67-.61-.916-.973.65v-3.045h4.472l-1.14 3.373h8.693v-.01l1.401 1.843h-7.488l-1.088 3.886Zm1.142 0 .78-2.786h7.491l2.12 2.786h-10.39Z" />
    <path d="M15.764 16.295H14.59v1.1h1.173v-1.1ZM14.004 16.295h-1.026v1.1h1.026v-1.1ZM12.318 16.295h-1.1v1.1h1.1v-1.1ZM10.705 16.295H9.532v1.1h1.173v-1.1ZM9.018 16.295H7.845v1.1h1.173v-1.1ZM7.259 16.295H6.086v1.1h1.173v-1.1Z" />
  </svg>
);
export default SvgPoositionSensor;
