import React, { useEffect, useCallback, useRef, useState } from "react";
import SelectComponent from "../../../../../Components/UiComponents/SelectComponent/SelectComponent";
import ExportTableData from "../../../../../Components/UiComponents/Table/TableComponents/ExportTableData/ExportTableData";
import { setTableData } from "../../../../../CentralStore/ExportLMSTable/dataSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DownlaodNew } from "../../../../../Components/IconComponents";
import DatePicker from "../../../Common/Form/DatePicker/DatePicker";
import DailyFloodReportTable from "../DailyFloodReportTable";
import { postDataWithBody,postDataWithBodyFrGIS } from "../../../../../Services/Services";
import { getChildRegionsById,getAllincidents } from "../../../../../Services/EndPoints";


const ViewDailyFloodReportHeader = ({ handleSelectedValues }) => {
  const selectedValuesRef = useRef(null);
  const [incidentSelectedValue, setIncidentSelectedValue] = useState("");
  const [districtSelectedValue, setDistrictSelectedValue] = useState("");
  const [blockSelectedValue, setBlockSelectedValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [incidentoptions,setIncidentoptions] =useState([]);
  const [districtoptions, setDistrictOptions]=useState("");
  const [blockoptions,setBlockOptions ]= useState("");
  const dispatch = useDispatch();
  const fileName = "report.csv";

  const dataFromStore = useSelector((state) => state.data);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setTableData({ columns: [], tableData: [] }));
  //   };
  // }, []);
  useEffect(() => {
    getDistrictList();
    getIncidentList();
  }, []);
  const getDistrictList = async () => {
     try {
          const distList = await postDataWithBody(getChildRegionsById, [process.env.REACT_APP_CONSTANT_STATE_ID], {});
          console.log("distList=", distList);
          const distArr=[];
          distList.forEach(function(node,index){
            distArr.push({"id":node.id,"value":node.name})
          });
          setDistrictOptions(distArr);
         
        } 
        catch (error) {
        console.error("Error fetching district list:", error);
       }
  };
  const getIncidentList = async () => {
    let inciListSanitizedArr = [];
    var incidentList = await postDataWithBodyFrGIS(getAllincidents,"",{});
    if(incidentList && incidentList.data){
      (incidentList.data).forEach(function(data,node){
        inciListSanitizedArr.push({"id":data.id,"value":data.name});
      })
      setIncidentoptions(inciListSanitizedArr);
    }
  };
  const getBlockList = async (districtId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [districtId],
        {}
      );
      const blockArr = blockList.map((node) => ({
        id: node.id,
        value: node.name,
      }));
      setBlockOptions(blockArr);
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  const onDistrictChangeHandler = (e) => {
    const value = e.target.value;
    getBlockList(value);
    setDistrictSelectedValue(value);
  };
  const onIncidentChangeHandler = (e) => {
    const value = e.target.value;
    setIncidentSelectedValue(value);
  };
  const onBlockChangeHandler = (e) => {
    const value = e.target.value;
    setBlockSelectedValue(value);
  };

  const onFromDateChangeHandler = (e) => {
    const value = e.target.value;
    setFromDate(value);
  };

  const onToDateChangeHandler = (e) => {
    const value = e.target.value;
    setToDate(value);
  };

  const handleApplyButtonClick = () => {
    const selectedValues = {
      incident: incidentSelectedValue,
      district: districtSelectedValue,
      block: blockSelectedValue,
      fromDate: fromDate,
      toDate: toDate,
    };
    console.log("Selected values:", selectedValues);
    selectedValuesRef.current = selectedValues;
    handleSelectedValues(selectedValues);
  };

  console.log("incidentselectedValue=", incidentSelectedValue);
  console.log("districtselectedValue=", districtSelectedValue);
  console.log("blockselectedValue=", blockSelectedValue);
  console.log("fromDate=", fromDate);
  console.log("toDate=", toDate);
  return (
    <div className="d-flex align-items-start justify-contents-center mt-4 ms-4">
      <div className="">
        <span className="ms-2"> Incident</span>
        <span className="ms-2">
          <SelectComponent
            onChange={onIncidentChangeHandler}
            showPlaceHolder={"Select"}
            options={incidentoptions}
          />
        </span>
      </div>
      <div className="ms-2">
        <span className="ms-2">District</span>
        <span className="ms-2">
          <SelectComponent
            onChange={onDistrictChangeHandler}
            showPlaceHolder={"Select"}
            options={districtoptions}
          />
        </span>
      </div>
      <div className="ms-2">
        <span className="ms-2">Block</span>
        <span className="ms-2">
          <SelectComponent
            onChange={onBlockChangeHandler}
            showPlaceHolder={"Select"}
            options={blockoptions}
          />
        </span>
      </div>
      <div className="ms-3">
        <span className="">From Date</span>
        <span className="ms-2">
          <input
            type="date"
            onChange={onFromDateChangeHandler}
            className="form-control form__input"
          />
        </span>
      </div>
      <div className="ms-2">
        <span className="">To Date</span>
        <span className="ms-2">
          <input
            type="date"
            onChange={onToDateChangeHandler}
            className={"form-control form__input"}
          />
        </span>
      </div>
      <div>
        <button className="applybutton-style" onClick={handleApplyButtonClick}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default ViewDailyFloodReportHeader;
