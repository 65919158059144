import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import LMSButton from "../../Common/LMSButton/LMSButton";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
  findRegionsByUser,
  getCurrBarChartData,
} from "../../../../Services/EndPoints";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import "./MISDistFloodReportTable.css";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import moment from "moment";
import { misDistReportConfig } from "./MISDistFloodReportConfig";
import { CSVLink } from "react-csv";

export default function MISDistFloodReportTable() {
  const { t } = useTranslation();
  const [districtData, setDistrictData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  useEffect(() => {
    getDistrictData();
  }, []);

  const fetchTableData = async () => {
    let data = await postDataWithBody(
      getCurrBarChartData + "get_district_mis_report",
      { reportedOn: selectedDate, districtId: selectedDistrictId },
      {}
    );
    setTableData(data);
  };
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);
      if (Array.isArray(distList) && distList.length > 0) {
        setDistrictData(distList);
        setSelectedDistrictId(distList[0].id);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  async function getDistrictDataByCode(event) {
    setSelectedDistrictId(event.target.value);
  }

  // Array of keys representing the columns
  const columnKeys = Array.from(
    { length: 124 },
    (_, i) => `headercol${i + 1}`
  ).filter((key, index) => ![0, 1, 2, 38].includes(index));

  const headers = columnKeys.map((key, index) => ({
    label: t(`misdistfloodreport.${key}`),
    key: Object.values(misDistReportConfig)[index],
  }));
  return (
    <div>
      <div className="flood__report--container">
        <div className="ps-2 d-flex gap-2">
          <div>
            <label className="mb-2">{t("floodreport.district")}</label>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
          <div className="">
            <label className="mb-2">{t("floodreport.dated_on_lbl")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>
          {/* <div className="">
            <label className="mb-2">{t("flooddisaster.datetxtto")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div> */}
          <div className="d-flex align-items-end mb-3 gap-2">
            <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={fetchTableData}
            />
            <CSVLink
              data={tableData}
              headers={headers}
              filename={`MIS_Dist_Flood_Report_${selectedDate}.csv`}>
              <LMSButton size="btn_sm" label="Export" />
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="p-3" style={{ overflowX: "auto" }}>
        <table
          className="table misflood_report-table"
          style={{ minWidth: "100px" }}>
          <thead>
            <tr>
              <th
                className="misflood_report-table-border"
                style={{ minWidth: "150px" }}>
                Block Name
              </th>
              {columnKeys.map((key) => (
                <th
                  className="misflood_report-table-border"
                  style={{ minWidth: "150px" }}
                  key={key}>
                  {t(`misdistfloodreport.${key}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData.map((data, rowIndex) => (
                <tr className="misflood_report-table-border" key={rowIndex}>
                  {Object.values(misDistReportConfig).map(
                    (configKey, colIndex) => (
                      <td
                        className="misflood_report-table-border"
                        key={colIndex}>
                        {data[configKey] !== undefined &&
                        data[configKey] !== null
                          ? data[configKey]
                          : 0}
                      </td>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columnKeys.length}>{t("No Data Available")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
