import React, { useEffect, useState, useRef, useContext } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../..";

const AnimalCenterTrendView = (props) => {
  const echartsDom = useRef(null);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const data = {
    facility_type: t("navlinklabels.animalshelter"),
    attributes: [
      { name: t("animaltreated"), count: props.dataval["Total Animals treated"] || 0 },
      { name: t("animalfed"), count: props.dataval["Total Animals fed"] || 0 },
    ],
  };

  const getLegendColor = (theme) => {
    return theme === "light" ? "#2B6591" : "#fff";
  };

  const options = {
    tooltip: {
      trigger: "item",
      confine: true,
    },
    legend: {
      orient: "vertical",
      right: "0",
      bottom: "center",
      selectedMode: false,
      textStyle: {
        color: getLegendColor(theme),
      },
    },
    xAxis: {
      type: "category",
      data: [t("animaltreated"), t("animalfed")],
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme),
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme),
        },
      },
      splitLine: {
        show: false,
      },
      min: 0,
    },
    series: [
      {
        name: t("animaltreated"),
        data: [data.attributes[0].count, 0],
        type: "bar",
        itemStyle: {
          color: "#327910",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "30",
      },
      {
        name: t("animalfed"),
        data: [0, data.attributes[1].count],
        type: "bar",
        itemStyle: {
          color: "#E95060",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "30",
      },
    ],
    grid: {
      left: "14%",
      bottom: "25%",
      top: "5%",
      right: "35%",
    },
  };

  useEffect(() => {
    const myChart = echarts.init(echartsDom.current);
    myChart.setOption(options);
  }, [props.dataval, t, theme]);

  return (
    <div className="card" style={{ width: "100%", height: "95%" }}>
      <div className="card-body" style={{ height: "100%" }}>
        <div className="card-title">
          {data.facility_type}
        </div>
        <div ref={echartsDom} style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};

export default AnimalCenterTrendView;
