import * as React from "react";
const SvgWind = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.994 2.869A1.25 1.25 0 1 1 6.875 5H1.25m6.619 7.131A1.25 1.25 0 1 0 8.75 10h-7.5m9.831-5.169A1.563 1.563 0 1 1 12.188 7.5H1.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgWind;
