import React from "react";
import {
  AnimalShelterIcon,
  ComunityKitchenIcon,
  FlCampIcon,
} from "../../../../../Components/IconComponents";
import "./FacilityRegistered.css";

export default function FacilityRegistered({ dataval }) {
  return (
    <div className="card">
      <div className="card-body">
        <div>
          Facility Registered
        </div>
        <div>
          {/* Community Kitchen */}
          <div className="facility-card">
            <ComunityKitchenIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">
              {dataval["Community Kitchen"] || 0}
              </div>
              <div className="fc-card-label">Community Kitchen</div>
            </div>
          </div>

          {/* Flood Relief Camp */}
          <div className="facility-card_ora">
            <FlCampIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">
              {dataval["Flood Relief Camp"] || 0}
              </div>
              <div className="fc-card-label">Flood Relief Camp</div>
            </div>
          </div>

          {/* Animal Shelter */}
          <div className="facility-card_pin">
            <AnimalShelterIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">
              {dataval["Animal Shelter"] || 0}
              </div>
              <div className="fc-card-label">Animal Shelter</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
