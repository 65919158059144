import * as React from "react";
const SvgGrid = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipRule="evenodd"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.125 11.104c0-4.485.048-5.979 5.98-5.979 5.93 0 5.978 1.494 5.978 5.98 0 4.484.02 5.978-5.979 5.978-5.998 0-5.979-1.494-5.979-5.979ZM23.916 11.104c0-4.485.049-5.979 5.98-5.979 5.93 0 5.979 1.494 5.979 5.98 0 4.484.019 5.978-5.98 5.978-5.997 0-5.979-1.494-5.979-5.979ZM5.125 29.896c0-4.485.048-5.98 5.98-5.98 5.93 0 5.978 1.495 5.978 5.98 0 4.484.02 5.979-5.979 5.979-5.998 0-5.979-1.495-5.979-5.98ZM23.916 29.896c0-4.485.049-5.98 5.98-5.98 5.93 0 5.979 1.495 5.979 5.98 0 4.484.019 5.979-5.98 5.979-5.997 0-5.979-1.495-5.979-5.98Z" />
    </g>
  </svg>
);
export default SvgGrid;
