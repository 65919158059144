import React, { useEffect, useRef } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";

import DMSHeader from "../DMSHeader/DMSHeader";
import CreateResourceEntityRegistrationForm from "./CreateResourceEntity/CreateResourceEntityRegistrationForm";
const ResourceManagementEntity = () => {
  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title="Resource Management" showmenu={true} />,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 11,
      id: 2,
      component: <CreateResourceEntityRegistrationForm/>,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default ResourceManagementEntity;
