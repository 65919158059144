import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import api from "../../../../Services/api";
import DynamicTabs from "../../Common/Tabs/Tabs";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import {toast, ToastContainer} from "react-toastify";
import useFormPersist from "react-hook-form-persist";
import { useKeycloak } from "@react-keycloak/web";

import {
  keycloakbaseurl,
  createRole,
  updateRole,
  createRoleintoRealm,
  getallRolesByRealm,
  deletePermissionsFromRole,
  getAllPermissions,
  getRoleDataByRoleNameURL,
  addPermissionsToRole
} from "../../../../Services/EndPoints";
import { CreateRoleConfig, schema } from "../../../../JsonData/CreateRoleConfig";
import { postDataWithBody, getData ,putDataWithBody} from "../../../../Services/Services";
import ModalResourceType from "../../../../Components/UiComponents/ModalResourceType/ModalResourceType";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

const RoleRegistrationForm = (props) => {

  const { keycloak } = useKeycloak();
  const [roleConfig, setRoleConfig] = useState(CreateRoleConfig);
  const [initDataLoadComplete, setInitDataLoadComplete] = useState(false);
  const [permissionsChanged, isPermissionsChanged] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const [permissionDataFrmService, setPermissionDataFrmService] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const navigate= useNavigate();
  const { state } = useLocation();
  let dataFrEdit = state?.data;
  const { t } = useTranslation();


  useEffect(() => {
    getInitFormData();
  }, []);

  const getInitFormData = async() => {
    await getAllUserPermission();
    setInitDataLoadComplete(true);
    if(dataFrEdit){
      reset(dataFrEdit);
      populatePermissionDataFrEdit(dataFrEdit.role_permission);
    }
  } 

  const populatePermissionDataFrEdit =(permissionData) => {

  
    let slectedPermissions = [];
    permissionData.forEach((permission) => {
      slectedPermissions.push(permission.permission_id);
    });
    let permissionDataTemp = CreateRoleConfig[0].fields[1].options;
    let permissionDataWthSlctdValus = addSelectedFlag(permissionDataTemp,slectedPermissions);
    
    CreateRoleConfig[0].fields[1]["options"] =permissionDataWthSlctdValus;
    setRoleConfig(CreateRoleConfig);
    //setRegionData(regionDataWthSlctdValus);
   
  }
  function addSelectedFlag(data,selectedValues) {
    return data.map(({ type, children = [], ...rest }) => {
      const o = { ...rest, type }
      if(selectedValues.indexOf(o.id)>-1) {
        o.checked = true
      }
      if (children.length)  o.children = addSelectedFlag(children,selectedValues);
      return o
    })
  }
  async function getAllUserPermission() {
    let permissionData= await getData(getAllPermissions);
    setPermissionDataFrmService(permissionData);
    let permissionDataSanitized  =flatToHier(permissionData);
    CreateRoleConfig[0].fields[1]["options"] = permissionDataSanitized;
    setPermissionData(permissionDataSanitized);
  }

  function flatToHier(flat){
    var roots = [] // things without parent
        var all = {}
        flat.forEach(function(item) {
          item["label"] = item.name;
          item["value"] = item.id;
          all[item.id] = item
        });
        Object.keys(all).forEach(function (id) {
            var item = all[id];
            if (item.parent_id == null) {
                roots.push(item)
            } else if (item.parent_id in all) {
                var p = all[item.parent_id]
                if (!('children' in p)) {
                    p.children = []
                }
                p.children.push(item)
            }
    });
    return roots;
  }
  const onHierDropdownChange = (currentNode, selectedNodes) => {

    isPermissionsChanged(true);
    let permissionArr = [];
    if(selectedNodes.length>0){
      selectedNodes.forEach(function(selectedNode) {
        if(selectedNode._depth == 0){
          permissionArr.push({"permission_id":currentNode.value});
          let level_one_children = selectedNode._children;
          level_one_children.forEach(function(node) {
            permissionArr.push({"permission_id":node});
            permissionDataFrmService.forEach(function(permission_node) {
               if(node == permission_node.parent_id){
                permissionArr.push({"permission_id":permission_node.id});
               }
            });
          });
        }
        else if(selectedNode._depth == 1){
          permissionArr.push({"permission_id":selectedNode.id});
        }
        
      });
    }
    setRolePermissionData(permissionArr);

  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
      {initDataLoadComplete === true && (<FormHandler
        register={register}
        errors={errors}
        fields={fields}
        onChange={onHierDropdownChange}
        control={control}
        columns={2}
        getValues={getValues}
      />)}</div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const getLoggedInUserAuthToken = async () => {
    return keycloak.token;
  };
  const getURMHeader = async () => {
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      "Authorization": "Bearer " + authToknObj,
    };
    return headers;
  }
  const getRoleDataByRoleName = async (roleName) => {
    let url = keycloakbaseurl +getRoleDataByRoleNameURL.replace("{REALM}", process.env.REACT_APP_URM_REALM)+roleName;
    let headers = await getURMHeader();
    const response = await api.getData(url, null, headers);
    return response.data;
  }
  const createRoleInApp= async (input) => {
    input["status"] = true;delete input["attributes"];
    return await postDataWithBody(createRole, input, {});
  }
  const updateRoleInApp= async (input) => {
    input["status"] = true;delete input["attributes"];
    return await putDataWithBody(updateRole, input, {});
  }
  
  const showSuccessMsg=  (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
  }
  const showErrorMsg=  (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
  }
  const assignPermissionsToRole= async (roleId) => {
    let roleObj = {"id":roleId,"role_permission":rolePermissionData}
    let res = await postDataWithBody(addPermissionsToRole+roleId,roleObj, {});
    if(res.status == "success"){
      showSuccessMsg("Role created successfully.");
      navigate("../roles");
    }
    else{
      showErrorMsg("An error occurred while creating the role.");
    }
   
  }
  const getSelectedPermissions = (data) => {
    if(permissionsChanged){
      return rolePermissionData;
    }
    else{
      let permissionMap = [];
      data.role_permission.forEach(function(permission){
        permissionMap.push({"permission_id":permission.permission_id})
      });
      return permissionMap;
    }
  }
  const submitHandler = async (data, e) => {
    let msg = "";
    let inputobj = {
      name: data.name,
      description: data.description,
      attributes: {
        created_on: [new Date().getTime()]
      }
    };
    e.preventDefault();
    if(dataFrEdit){
      inputobj["id"] = dataFrEdit.id;
      inputobj["role_permission"] = getSelectedPermissions(data);
      let res =  await putDataWithBody(deletePermissionsFromRole+dataFrEdit.id, {}, {});
      if(res.status == "success"){
        res = await updateRoleInApp(inputobj);
        if(res.id){
          if(res.status == "success"){
            showSuccessMsg("Role updated successfully.");
            navigate("../roles");
          }
          else{
            showErrorMsg("An error occurred while updating the role.");
          }
        }
      }
      
    }
    else{
    
      let url = keycloakbaseurl +createRoleintoRealm.replace("{REALM}", process.env.REACT_APP_URM_REALM);
      let headers = await getURMHeader();
      try {
        const response = await api.postData(url, headers, JSON.stringify(inputobj));
        if (response.status == 201) {
          let roleobj = await getRoleDataByRoleName(data.name);
          inputobj["urm_role_id"] = roleobj.id;
          let res = await createRoleInApp(inputobj);
          if(res.id){
            await assignPermissionsToRole(res.id);
          }
        }
      } 
      catch (e) {
        showErrorMsg(e.response.data.errorMessage);
      }
    }
  };
  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };

  const tabsData = generateTabsData(roleConfig);

  return (
    <div className="mt-4">
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="AgancyRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>

                      
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {activeTab > 0 && (
                <Button
                  variant="secondary"
                  className="previousbutton-style mx-2"
                  onClick={previousHandler}
                >
                  Previous
                </Button>
              )}
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                    {t('saveAndSubmit')}
                     </Button>
              )}
              {tabsData.length - 1 !== activeTab && (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={saveTabDataHandler}
                >
                  Save & Next
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      
    </div>
  );
};
export default RoleRegistrationForm;
