import * as React from "react";
const SvgCurrentweekicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5} cy={5} r={5} fill="url(#currentweekicon_svg__a)" />
    <defs>
      <linearGradient
        id="currentweekicon_svg__a"
        x1={5}
        y1={15}
        x2={15}
        y2={5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E21F70" />
        <stop offset={1} stopColor="#FF4D2C" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCurrentweekicon;
