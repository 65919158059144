import * as React from "react";
const SvgWifiBlue = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 8.367a7.333 7.333 0 0 1 9.387 0M.946 6a10.667 10.667 0 0 1 14.107 0M5.687 10.74a4 4 0 0 1 4.633 0M8 13.334h.008"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgWifiBlue;
