export const columnsForExport = [
  { label: "Facility", key: "facility_name" },
  { label: "District", key: "district_name" },
  { label: "Block", key: "block_name" },
  // { label: "Creatd On", key: "created_on" },
  // { label: "Status", key: "open_status" },
  { label: "Open Date", key: "open_date" },
  { label: "Close Date", key: "close_date" },
  // { label: "Operational Date", key: "operational_date" },
  // { label: "Operational", key: "operational_attribute" },
  { label: "Breakfast", key: "breakfast" },
  { label: "Lunch", key: "lunch" },
  { label: "Dinner", key: "dinner" },
  { label: "Total Meals", key: "total_meals" },
];
