import React from "react";
import CreateResourceDetails from "../../../../Components/UiComponents/CreateResourceDetails/CreateResourceDetails";
import { useRef } from "react";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import "./Resource.css";
import { Button } from "react-bootstrap";
import ResourceTypeDetails from "../../../../Components/UiComponents/ResourceTypeDetails/ResourceTypeDetails";
const Resource = () => {
  const ref1 = useRef();

  return (
    <div className="resource__container">
     

      <div className="row ">
        <div className="col-md-6 roleuser__form--left">
          <label className="form__label"> Resource Category:</label>
          <div className="d-flex ">
            <SelectComponent classList={"w-75 me-3"}
              options={[
                { id: "DEMO", value: "DEMO" },
                { id: "DEMO", value: "DEMO" },
                { id: "DEMO", value: "DEMO" },
                { id: "DEMO", value: "DEMO" },
                { id: "DEMO", value: "DEMO" },
                { id: "DEMO", value: "DEMO" },
              ]}
            />

            <CreateResourceDetails />
          </div>
        </div>
        <div className="col-md-6  roleuser__form--left ">
          <label className="form__label">
            <span className="asterisk__create--resource">*</span>
            Resource Type:
          </label>

          <div className="d-flex"><SelectComponent classList={"w-75 me-3"}
            options={[
              { id: "DEMO", value: "DEMO" },
              { id: "DEMO", value: "DEMO" },
              { id: "DEMO", value: "DEMO" },
              { id: "DEMO", value: "DEMO" },
              { id: "DEMO", value: "DEMO" },
              { id: "DEMO", value: "DEMO" },
            ]}
          />
          <ResourceTypeDetails/></div>
          
        </div>
      </div>

      <div className="row ">
        <div className="col-md-6 roleuser__form--left ">
          <label className="form__label">
            <span className="asterisk__create--resource">*</span>
            Resource Name:
          </label>
          <input
            className="form-control form_txt_fld form-control-sm w-75"
            type="text"
            ref={ref1}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <Button variant="primary" className="resource__mgmt--button">
          Save
        </Button>

        <Button type="submit" className="resource__mgmt--button" variant="primary">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Resource;
