import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import LMSButton from "../../Common/LMSButton/LMSButton";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
  findRegionsByUser,
  getChildRegionsById,
  getCurrBarChartData,
  misReportData,
  misReportDataCumm,
} from "../../../../Services/EndPoints";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import "./MISDistFloodReportTable.css";
import DatePicker from "../../Common/Form/DatePicker/DatePicker";
import moment from "moment";
import { misColumns, misDistReportConfig } from "./MISDistFloodReportConfig";
import { CSVLink } from "react-csv";
import CustomTable from "../../../../Components/UiComponents/Table/CustomTable";
import Loader from "react-spinner-loader";
import { useDispatch, useSelector } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import ExportTableData from "../../../../Components/UiComponents/Table/TableComponents/ExportTableData/ExportTableData";
import { toast } from "react-toastify";
 
const viewOptions = [
  {
    value: "Dist",
    id: "dist",
  },
  {
    value: "State",
    id: "state",
  },
  {
    value: "District Datewise",
    id: "dist_date",
  },
  {
    value: "State Datewise",
    id: "state_date",
  },
];
 
const newDateColumn = {
  Header: "Date",
  accessor: "date",
  disableSortBy: true,
  showInitially: true,
  sortType:"basic",
  Cell: (props) => {
    return props.value ? moment(props.value).format("DD/MM/YY") : ""; // Check for null or empty value
  },
};
 
const newStateColumn = {
  Header: "District Name",
  accessor: "name",
  disableSortBy: true,
  showInitially: true,
};
 
 
const newDistrict = {
  Header: "",
  // Header: "",
  accessor: "name",
  disableSortBy: true,
  showInitially: true,
};
 
export default function MISFloodReportTable() {
  const { t } = useTranslation();
  const [districtData, setDistrictData] = useState([]);
  const [tableData, setData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [loader, setLoader] = useState(false);
 
  const dataFromStore = useSelector((store) => store.data);
 
  const [selectedToDate, setSelectedToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
 
  const [column, setColumn] = useState(misColumns);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [selectDisable, setSelectDisable] = useState(false);
  const [selectedView, setSelectedView] = useState("dist");
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageOptions, setPageOptions] = useState([]);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
 
 
  const dispatch = useDispatch();
 
  useEffect(() => {
    setSelectedView("dist");
    getDistrictData();
    getBlockListByDistId("169");
    setSlctdBlkId("All");
    onChangeView();
  }, []);

  const currentTableData = useMemo(() => {
    return selectedView === "dist_date" || selectedView === "state_date" ? tableData : tableData1;
  },[selectedView, tableData, tableData1]);
 
  useEffect(() => {
    setPageOptions([...Array(Math.ceil(currentTableData.length / pageSize)).keys()]);
    console.log("data2")
  }, [currentTableData, pageSize]);
 
  const gotoPage = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < pageOptions.length) {
      setPageIndex(pageNumber);
      setCanPreviousPage(pageNumber > 0);
      // setCanNextPage(pageNumber < pageOptions.length - 1);
    }
  };
 
  const nextPage = () => {
    // if (pageIndex < pageOptions.length - 1) {
      gotoPage(pageIndex + 1);
    // }
  };
 
  const previousPage = () => {
    if (pageIndex > 0) {
      gotoPage(pageIndex - 1);
    }
  };
 
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0); // Reset to first page when page size changes
  };
 
  const displayedData = useMemo(() => {
    return currentTableData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
  }, [currentTableData, pageIndex, pageSize]); // Get currently displayed data
 
  const calculateTotals = (data) => {
    const totals = {};
 
    data.forEach(row => {
      for (const key in row) {
        if (typeof row[key] === 'number') { // Ensure the value is a number
          totals[key] = (totals[key] || 0) + row[key];
        }
      }
    });
 
    return totals;
  };
 
  const generateDateArray = (fromDate, toDate) => {
    const dateArray = [];
    let currentDate = new Date(fromDate);
    const end = new Date(toDate);
 
    while (currentDate <= end) {
      dateArray.push(currentDate.toISOString().split("T")[0]); // format as 'YYYY-MM-DD'
      currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
    }
 
    return dateArray;
  };
 
  // Modified fetchTableData1 and fetchTableData2 functions to return data
const fetchTableData1 = async () => {
  setLoader(true);
  let regionId = [];
 
  if (selectedView === "dist") {
    if (slctdBlkId === "All") {
      regionId = blockData
        .filter((block) => block.id !== "All")
        .map((block) => block.id);
    } else {
      regionId.push(slctdBlkId);
    }
  } else if (selectedView === "state") {
    if (selectedDistrictId === "All") {
      regionId = stateData.map((state) => state.id);
    } else {
      regionId.push(selectedDistrictId);
    }
  } else if (selectedView === "dist_date") {
    regionId.push(slctdBlkId);
  } else if (selectedView === "state_date") {
    regionId.push(selectedDistrictId);
  }
  let data = await postDataWithBody(
    misReportData,
    {
      region_id_list: regionId,
      from_date: selectedFromDate,
      to_date: selectedToDate,
      view_input: selectedView,
    },
    {}
  );
 
  const formatReportDates = (report) => {
    return report.map((item) => ({
      ...item,
      date: moment(item.date).format("YYYY-MM-DD"),
    }));
  };
 
  let fetchedData = [];
  if (selectedView === "dist") {
    fetchedData = data.mis_flood_report_district;
  } else if (selectedView === "state") {
    fetchedData = data.mis_flood_report_state;
  } else if (selectedView === "dist_date") {
    fetchedData = formatReportDates(data.mis_flood_report_district_date);
  } else if (selectedView === "state_date") {
    fetchedData = formatReportDates(data.mis_flood_report_state_date);
  }
 
  if (selectedView === "dist_date" || selectedView === "state_date") {
    fetchedData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  }
 
  // setTableData1(fetchedData);
  setLoader(false);
  return fetchedData;
};
 
const fetchTableData2 = async () => {
  setLoader(true);
  let regionId = [];
 
  if (selectedView === "dist") {
    if (slctdBlkId === "All") {
      regionId = blockData
        .filter((block) => block.id !== "All")
        .map((block) => block.id);
    } else {
      regionId.push(slctdBlkId);
    }
  } else if (selectedView === "state") {
    if (selectedDistrictId === "All") {
      regionId = stateData.map((state) => state.id);
    } else {
      regionId.push(selectedDistrictId);
    }
  } else if (selectedView === "dist_date") {
    regionId.push(slctdBlkId);
  } else if (selectedView === "state_date") {
    regionId.push(selectedDistrictId);
  }
  const fromDateArr = selectedFromDate; // Replace with actual fromDate
  const toDateArr = selectedToDate;
  const dateArray = generateDateArray(fromDateArr, toDateArr);
  let data = await postDataWithBody(
    misReportDataCumm,
    {
      region_id_list: regionId,
      date: dateArray,
      view_input: selectedView,
    },
    {}
  );
 
  const formatReportDates = (report) => {
    return report.map((item) => ({
      ...item,
      date: moment(item.date).format("YYYY-MM-DD"),
    }));
  };
 
  let fetchedData = [];
  if (selectedView === "dist") {
    fetchedData = data.mis_flood_report_district;
  } else if (selectedView === "state") {
    fetchedData = data.mis_flood_report_state;
  } else if (selectedView === "dist_date") {
    fetchedData = formatReportDates(data.mis_flood_report_district_date);
  } else if (selectedView === "state_date") {
    fetchedData = formatReportDates(data.mis_flood_report_state_date);
  }
 
  if (selectedView === "dist_date" || selectedView === "state_date") {
    fetchedData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  }
 
  setLoader(false);
  return fetchedData;
};

const replaceNullWithNA = (data) => {
  return data.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([key, value]) => [key, value === null ? "-" : value])
    )
  );
};

const fetchData1Only = async () => {
  const data1 = await fetchTableData1();
  console.log(data1, "data1 only");

  // If any additional processing is needed for data1, you can do it here.
  const processedData = replaceNullWithNA(data1);

  setTableData1(processedData); // Directly update with data1
};
 
// New function to combine the data
const fetchAndCombineData = async () => {
  const data1 = await fetchTableData1();
  const data2 = await fetchTableData2();

  console.log(data1,"data")
 
  // Merging data1 and data2 by a common key, e.g., `date` or `region_id`
  const combinedData = data1.map(item1 => {
    const matchingItem = data2.find(item2 => item2.date === item1.date); // or match by `region_id` if more appropriate
    return { ...item1, ...matchingItem }; // Merging properties of both objects
  });

  const processedData = replaceNullWithNA(combinedData);
 
  setData(processedData); // Update tableData with the combined result
};
 
// Calling fetchAndCombineData in useEffect or on a button click to fetch and combine data
useEffect(() => {
  fetchAndCombineData();
}, []);
 
 
 
 
  const headers = visibleColumns.map((key, index) => ({
    label: t(key.Header),
    key: key.accessor,
  }));
 
  const sendDataToCentralStore = (data) => {
    if (data?.length > 0) {
      const totals = calculateTotals(data);
 
      // Create the totals row to match the data structure
      const totalsRow = {
        name: "TOTAL",
        date: "", // or null if you want to merge cells
        ...totals
      };
 
      const dataWithTotals = [...data, totalsRow]; // Append totals row
 
      const columns = headers;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, dataWithTotals), // Use data with totals row
        })
      );
    }
  };
 
 
  async function getDistrictDataByCode(event) {
    setSlctdBlkId("All");
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
 
  const onChangeView = (selectedView) => {
    setData([]);
    setTableData1([]);
    selectedView && setSelectedView(selectedView);
    setSelectedDistrictId("All");
    setSlctdBlkId("All");
 
    if (selectedView === "state") {
      const updatedColumns = [
        newStateColumn,
        ...misColumns.filter(
          (_, index) => index !== 0 && index !== 1 && index !== 2
        ),
      ];
      setSelectDisable(true);
      setBlockData(blockData.filter((item) => item.id !== "All"));
      setDistrictData([{ value: "All", id: "All" }, ...districtData]);
      setColumn(updatedColumns);
      setSelectedDistrictId("All");
    } else if (selectedView === "state_date") {
      const updatedColumns = [
        newDateColumn,
        newStateColumn,
        ...misColumns.filter(
          (_, index) => index !== 0 && index !== 1 && index !== 2
        ),
      ];
      setSelectDisable(true);
      setDistrictData(districtData.filter((item) => item.id !== "All"));
      setBlockData(blockData.filter((item) => item.id !== "All"));
      setColumn(updatedColumns);
      setSelectedDistrictId("169");
    } else if (selectedView === "dist_date") {
      const updatedColumns = [
        newDistrict,
        newDateColumn,
        ...misColumns.filter((_, index) => index !== 0 && index !== 1),
      ];
 
      setDistrictData(districtData.filter((item) => item.id !== "All"));
      setBlockData(blockData.filter((item) => item.id !== "All"));
      setColumn(updatedColumns);
      setSelectDisable(false);
      setSlctdBlkId("4691");
    } else {
      const updatedColumns = [
        newDistrict,
        ...misColumns.filter((_, index) => index !== 0 && index !== 1),
      ];
      setBlockData(blockData.filter((item) => item.id !== "All"));
      setDistrictData(districtData.filter((item) => item.id !== "All"));
      if (!blockData.some((item) => item.id === "All")) {
        setBlockData([{ id: "All", value: "All" }, ...blockData]);
      }
      setColumn(updatedColumns);
      setSelectDisable(false);
    }
  };
 
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);
 
      if (Array.isArray(distList) && distList.length > 0) {
        const formattedDistList = distList.map((item) => ({
          value: item.value,
          id: item.id,
        }));
        setStateData(formattedDistList);
        // Add "All" conditionally based on selectedView
        if (selectedView === "state") {
          setDistrictData([{ value: "All", id: "All" }, ...formattedDistList]);
        } else {
          setDistrictData(formattedDistList);
        }
 
        setSelectedDistrictId("169");
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };
 
  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
 
      // Add "All" conditionally based on selectedView
      if (selectedView === "dist") {
        blockList.unshift({ id: "All", value: "All" });
      }
 
      if (selectedView === "dist_date") {
        setSlctdBlkId(blockList[0].id)
       console.log(blockList,"blockList")
      }
 
      setBlockData(blockList);
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
 
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
 
  const saveHandler = () => {
    if (!currentTableData || currentTableData.length === 0) {
      toast.error("No data to save!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      return;
    }
 
    sendDataToCentralStore(currentTableData);
    setIsExportDisabled(false);
 
    toast.success("Saved Successfully", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
  };
 
  const totalsRowStyle = {
    backgroundColor: '#f0f0f0', // Light grey background for TOTAL row
    fontWeight: 'bold',          // Bold text
    textAlign: 'right',          // Align text to the right
    fontSize: '14px',            // Adjust font size
    color: '#333',               // Dark text color
    borderTop: '2px solid #000', // Top border for separation
    padding: '8px',              // Padding for spacing
  };
 
const totals = calculateTotals(displayedData);
 
console.log(tableData1,"TableData");
console.log(totals, "Calculated Totals:");
 
  return (
    <div>
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="flood__report--container">
        <div className="ps-2 d-flex gap-2">
          <div>
            <label className="mb-2">{"View"}</label>
            <SelectComponent
              id="view"
              options={viewOptions}
              onChange={(e) => {
                onChangeView(e.target.value);
                setSelectedView(e.target.value);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div>
            <label className="mb-2">{t("floodreport.district")}</label>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          {!selectDisable && (
            <div style={{ minWidth: "10rem" }}>
              <label className="mb-2">Block</label>
              <SelectComponent
                id="block_drop"
                value={slctdBlkId}
                onChange={setSelectedBlockIdVal}
                placeholder="All Blocks"
                options={blockData}
                disabled={selectDisable}
                // style={{
                //   cursor: selectDisable ? "not-allowed" : "pointer",
                // }}
                // classList={selectDisable && "disable_select"}
              />
            </div>
          )}
          <div className="">
            <label className="mb-2">{t("flooddisaster.datetxtfrom")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                // selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedFromDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>
          <div className="">
            <label className="mb-2">{t("flooddisaster.datetxtto")}</label>
            <div className="date_picker_flood_container me-1">
              <DatePicker
                // selected={selectedDate}
                defaultDateValue={moment().format("YYYY-MM-DD")}
                maxDate={new Date().toISOString().split("T")[0]}
                name="flood_date_picker"
                setDate={setSelectedToDate}
                className="form-control date-picker date_picker_flood"
              />
            </div>
          </div>
 
          <div className="d-flex align-items-end mb-3 gap-2">
          <LMSButton
            size="btn_sm"
            label={t("flooddisaster.apply")}
            onClick={() => {
            if ( selectedView === "dist" || selectedView === "state") {
              fetchData1Only();
            } else if (selectedView === "dist_date" || selectedView === "state_date") {
             fetchAndCombineData();
            }
            }}
            />

            <LMSButton size="btn_sm" label={"Save"} onClick={saveHandler} />
            <ExportTableData
                data={dataFromStore.tableData}
                columns={headers}
                filename={
                   selectedView === "dist"
                     ? `MIS_Dist_Flood_Report.xlsx`
                  : selectedView === "state"
                     ? `MIS_State_Flood_Report.xlsx`
                  : selectedView === "dist_date"
                     ? `MIS_Dist_Datewise_Flood_Report.xlsx`
                  : selectedView === "state_date"
                     ? `MIS_State_Datewise_Flood_Report.xlsx`
                  : `MIS_Flood_Report.xlsx`
                }
                id="ExportTableData"
                style={{
                padding: "10px",
                }}
                isExportDisabled={isExportDisabled}
                setIsExportDisabled={setIsExportDisabled}
                selectedView={selectedView}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                useLMSButton={true}
           />
          </div>
        </div>
      </div>
      <CustomTable
  data={[
    ...displayedData,
    {
      name: "TOTAL",
      date: null,
      ...totals // Spread the calculated totals here
    }
  ]}
  numberOfRows={pageSize}
  showFilter={false}
  columns={column}
  showExport={false}
  showReset={false}
  onExpand={false}
  showSearch={true}
  isHeaderFixed={true}
  enableColumnFilters={true}
  showFilterColumn={true}
  setVisibleColumns={setVisibleColumns}
  selectedView={selectedView}
  tableStyle={{
    border: "#f0f0f0",
    padding: "2px",
    borderRadius: "12px",
  }}
  rowStyle={(row) => (row.original.name === "TOTAL" ? totalsRowStyle : {})}
/>
    <div>
      <div className="float-start m-2 mt-1 ms-3 pagecount-style">
       {t("showingentriestable.showing")} {pageIndex * pageSize + 1}{" "}
       {t("showingentriestable.to")}{" "}
       {Math.min((pageIndex + 1) * pageSize, currentTableData.length)}{" "}
       {t("showingentriestable.of")} {currentTableData.length}{" "}
       {t("showingentriestable.entries")}
       <span style={{ marginLeft: "70px", fontStyle: "italic" }}>
         [<span style={{ color: "red",fontWeight: "bold" }}>'<span style={{ color: "white",fontWeight: "bold", fontSize: "17px" }}> - </span> '</span> represents fields where data is not available]
       </span>
   </div>
   <div className="pagination">
      <div className="d-flex">
       <span className="pagination_gotopage">
         Go to page:
        <input
        type="number"
        defaultValue={pageIndex + 1}
        onChange={(e) => {
          const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(pageNumber);
        }}
        className="pagination_gotopage-input"
       />
     </span>
     <span>
      <select
        className="pagination_select"
        value={pageSize}
        onChange={handlePageSizeChange}
      >
        {[10, 50, 100, 500].map((size) => (
          <option key={size} value={size}>
            show {size}
          </option>
        ))}
      </select>
    </span>
  </div>
    <button
    className="pagebtn mx-2"
    onClick={previousPage}
    disabled={!canPreviousPage}
    >
    {"<"}
    </button>
    <button className="pagination_page">{pageIndex + 1}</button>
    <button
    className="pagebtn"
    onClick={nextPage}
    // disabled={!canNextPage}
    >
    {">"}
    </button>
    <span className="right_pageno">
    {t("pagination.page_of", {
      page: pageIndex + 1,
      total: pageOptions.length,
    })}
     </span>
    </div>
  </div>
    </div>
  );
}