import * as React from "react";
const SvgPtz2 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.258 13.477-4.89-8.005-5.11 7.867 10 .138Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.812 7.836 9.13 6.09a.3.3 0 0 1 .483.006l1.279 1.775"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz2;
