import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import useFormPersist from "react-hook-form-persist";
import "./CreateResourceRegForm.css";
import { useTranslation } from 'react-i18next';
import {
  postDataWithBody,
  getData,
  putDataWithBody,
} from "../../../../Services/Services";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "react-spinner-loader";
import {
  schema,
  resourceFormConfig,
} from "../../../../JsonData/CreateResourceEntityConfig";
import {
  resourceCatListURL,
  findResourcesById,
  updateResourceURL,
  getContacts,
  createResourceURL,
  regionListURL,
  agencyListURL,
  getFacilities,
  resourceActivityListURL,
  resourceNameListURL,
} from "../../../../Services/EndPoints";
const CreateResourceRegForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [resourceConfig, setResourceConfig] = useState(resourceFormConfig);
  const [facilityData, setFacilityData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [agencyData, setAgencyData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [resourcedataById, setResourcedataById] = useState(null);
  const [regionChanged, isRegionChanged] = useState(false);
  const [regionDataSelected, setRegionDataSelected] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [resourceActivityList, setResourceActivityList] = useState([]);
  const [resourceNameList, setResourceNameList] = useState([]);
  const { t } = useTranslation();
  const [resourceCategoriesList, setResourceCategoriesList] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useLocation();
  let id = state?.data;
  let agencyIdList = [],
    regionIdList = [];
  let contactIdListGlobal = [],
    agencyIdListGlobal = [],
    regionidlistGlobal = [];
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });
  
  const rscractivity = useWatch({
    control,
    name: "rsrc_activity_id",
  });
  const rscrcat = useWatch({
    control,
    name: "rsrc_cat_id",
  });
  useEffect(() => {
    const phone1Value = watch('phone1');
    if (phone1Value) {
      console.log('Phone1 value:', phone1Value);
    }
  }, [watch('phone1')]);

  useEffect(() => {
    getInitFormData();
  }, []);

  const getInitFormData = async () => {
    setLoader(true);
    await getFacilityList();
    await getContactList();
    await getAgencyList();
    await getRegionList();
    await getResourceActivityList();
  
    if (id) {
      populateResourceFormFrEdit(id);
    }
    setLoader(false);
  };
  useEffect(() => {
    const rscrcategory = getValues("rsrc_cat_id");
    const rscractivity = getValues("rsrc_activity_id");
    if (
      rscrcategory &&
      rscrcategory != "" &&
      rscractivity &&
      rscractivity != ""
    ) {
      getResourceNameList(rscractivity, rscrcategory);
    }
  }, [rscrcat]);

  useEffect(() => {
    const rscractivity = getValues("rsrc_activity_id");
    if (rscractivity && rscractivity != "") {
      getResourceCategoryList(rscractivity);
    }
  }, [rscractivity]);

  const onHierDropdownChange = (currentNode, selectedNodes) => {
    regionIdList = [];
    isRegionChanged(true);
    selectedNodes.forEach((node, index) => {
      regionIdList.push({ region_id: node.id });
    });
    setRegionDataSelected(regionIdList);
  };

  const getFacilityList = async () => {
    var facilityList = await getData(getFacilities);
    for (let facility in facilityList) {
      facilityList[facility]["label"] = facilityList[facility]["name"];
      facilityList[facility]["value"] = facilityList[facility]["id"];
    }
    resourceFormConfig[0].fields[5]["options"] = facilityList;
    setResourceConfig(resourceFormConfig);
    setFacilityData(facilityList);
  };
  const getAgencyList = async () => {
    var agencyList = await getData(agencyListURL);
    for (let agency in agencyList) {
      agencyList[agency]["label"] = agencyList[agency]["name"];
      agencyList[agency]["value"] = agencyList[agency]["id"];
    }
    resourceFormConfig[0].fields[6]["options"] = agencyList;
    setResourceConfig(resourceFormConfig);
    setAgencyData(agencyList);
  };

  const getContactList = async () => {
    var contactList = await getData(getContacts);
    for (let contact in contactList) {
      contactList[contact]["label"] = contactList[contact]["name"];
      contactList[contact]["value"] = contactList[contact]["id"];
    }
    resourceFormConfig[0].fields[8]["options"] = contactList;
    setResourceConfig(resourceFormConfig);
    contactIdListGlobal = contactList;
    setContactData(contactList);
  };
  const getRegionList = async () => {
    var regionList = await getData(regionListURL);
    let hierregionData = flatToHierarchy(regionList, "parent_region_id");
    resourceFormConfig[0].fields[7]["options"] = hierregionData[0];
    setResourceConfig(resourceFormConfig);
    regionidlistGlobal = regionList;
    setRegionData(regionList);
  };

  const flatToHierarchy = (flat, parent_key_name) => {
    var roots = []; // things without parent
    var all = {};
    flat.forEach(function (item) {
      all[item.id] = item;
    });
    Object.keys(all).forEach(function (id) {
      var item = all[id];

      item["id"] = item.id + "";
      item["label"] = item.name;
      item["value"] = item.id + "";
      if (item[parent_key_name] == 0) {
        console.log("Test");
        roots.push(item);
      } else if (item[parent_key_name] in all) {
        var p = all[item[parent_key_name]];
        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });
    console.log(JSON.stringify(roots));
    return roots;
  };

  const getResourceActivityList = async () => {
    var resourceList = await getData(resourceActivityListURL);
    for (let resource in resourceList) {
      resourceList[resource]["label"] = resourceList[resource]["activity"];
      resourceList[resource]["value"] = resourceList[resource]["activity"];
    }
    resourceFormConfig[0].fields[0]["options"] = resourceList;
    setResourceConfig(resourceFormConfig);
    setResourceActivityList(resourceList);
    await getResourceCategoryList(resourceList[0]["activity"]);
  };
  const getResourceCategoryList = async (activity) => {
    var resourceCategoryList = await getData(resourceCatListURL + activity);
    for (let resourceCat in resourceCategoryList) {
      resourceCategoryList[resourceCat]["label"] =
        resourceCategoryList[resourceCat]["category"];
      resourceCategoryList[resourceCat]["value"] =
        resourceCategoryList[resourceCat]["category"];
    }
    resourceFormConfig[0].fields[1]["options"] = resourceCategoryList;
    setResourceConfig(resourceFormConfig);
    setResourceCategoriesList(resourceCategoryList);
    setValue("rsrc_cat_id", resourceCategoryList[0].value);
    getResourceNameList(activity, resourceCategoryList[0].value);
  };
  const getResourceNameList = async (activity, category) => {
    let resourceNameList = [];
    resourceFormConfig[0].fields[2]["options"] = [];
    setResourceConfig(resourceFormConfig);
    setResourceNameList(resourceNameList);
    resourceNameList = await getData(
      resourceNameListURL+"?activity="+ activity + "&category=" + category
    );
    for (let resourceName in resourceNameList) {
      resourceNameList[resourceName]["label"] =
        resourceNameList[resourceName]["name"];
      resourceNameList[resourceName]["value"] =
        resourceNameList[resourceName]["name"];
    }
    resourceFormConfig[0].fields[2]["options"] = resourceNameList;
    setResourceConfig(resourceFormConfig);
    setResourceNameList(resourceNameList);
    //setValue("name", resourceNameList[0].value);
  };

  const populateResourceFormFrEdit = async (id) => {
   
    var resourceData = await postDataWithBody(findResourcesById, [id], {});
    console.log("resourceData=", resourceData);
    resourceData = resourceData[0];
    console.log("resourceData=", resourceData[0]);
    setResourcedataById(resourceData);
    reset(resourceData);
    populateContactDataFrEdit(resourceData);
    //populateAgencyDataFrEdit(resourceData);
    populateRegionDataFrEdit(resourceData);
    setLoader(true);
    setTimeout(function(){
      setValue("rsrc_cat_id",resourceData.rsrc_cat_id);
      document.getElementById("rsrc_cat_id").dispatchEvent(new Event("change"));;
      getResourceNameList(resourceData.rsrc_activity_id, resourceData.rsrc_cat_id);
      setTimeout(() => {
        setValue("name",resourceData.name); 
        setLoader(false);
      }, 2000);
    },2000);
   
  };

  const populateAgencyDataFrEdit = (resourceData) => {
    let selectedAgencies = [];
    resourceData.resource_agency_map.forEach((agency, index) => {
      selectedAgencies.push(agency.agency_id + "");
    });
    agencyIdListGlobal.forEach((agency, index) => {
      if (selectedAgencies.indexOf(agency.id) > -1) {
        agency["checked"] = true;
      }
    });
    let hieragencyData = flatToHierarchy(
      agencyIdListGlobal,
      "parent_agency_id"
    );
    resourceFormConfig[0].fields[6]["options"] = hieragencyData[0];
    setResourceConfig(resourceFormConfig);
  };

  const populateRegionDataFrEdit = (resourceData) => {
    let slectedRegions = [];
    resourceData.resource_region_map.forEach((resource, index) => {
      slectedRegions.push(resource.region_id + "");
    });
    let regionDataTemp = resourceFormConfig[0].fields[7].options;
    let regionDataWthSlctdValus = addSelectedFlag(
      [regionDataTemp],
      slectedRegions
    );

    resourceFormConfig[0].fields[7]["options"] = regionDataWthSlctdValus[0];
    setResourceConfig(resourceFormConfig);
    setRegionData(regionDataWthSlctdValus);
  };
  function addSelectedFlag(data, selectedValues) {
    return data.map(({ type, children = [], ...rest }) => {
      const o = { ...rest, type };
      if (selectedValues.indexOf(o.id) > -1) {
        o.checked = true;
      }
      if (children.length)
        o.children = addSelectedFlag(children, selectedValues);
      return o;
    });
  }
  const populateContactDataFrEdit = (resourceData) => {
    let contactArr = [];
    let contactIdNameMap = {};
    contactIdListGlobal.forEach((contact, index) => {
      contactIdNameMap[contact.id] = contact.name;
    });
    resourceData.resource_contact_map.forEach((contact, index) => {
      contactArr.push({
        label: contactIdNameMap[contact["contact_id"]],
        value: contact["contact_id"],
      });
    });
    setValue("contact_list", contactArr);
  };
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        {contactData.length > 0 &&
          facilityData.length > 0 &&
          agencyData.length > 0 &&
          regionData.length > 0 &&
          resourceActivityList.length > 0 && (
            <FormHandler
              register={register}
              errors={errors}
              fields={fields}
              control={control}
              onChange={onHierDropdownChange}
              columns={2}
              getValues={getValues}
            />
          )}
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    e.preventDefault();
    setIsDisabled(true);
    // setLoader(true);
    data["resource_contact_map"] = getContactListFrUpsert();
    // data["resource_agency_map"]=getSanitizedAgencyList();
    data["resource_region_map"] = getSanitizedRegionList(data);
    data["status"] = true;
    console.log(JSON.stringify(data));
    let msg = null;
    if (id != null) {
      data.id = id;
      delete data["created_on"];
      delete data["updated_on"];
      var res = await putDataWithBody(updateResourceURL, data, {});
      // setLoader(false);
      msg = "Resource updated successfully.";
    } else {
      console.log("data phone number-------------",data.phone1);
      
      var res = await postDataWithBody(createResourceURL, data, {});
      // setLoader(false);
      msg = "Resource created successfully.";
    }

    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
    navToResourceList(res);
    setIsDisabled(false);
  };
  const getSanitizedAgencyList = () => {
    if (id) {
      let existingAgnecyIdMap = {};
      resourcedataById.resource_agency_map.forEach((agency, index) => {
        existingAgnecyIdMap[agency.agency_id] = agency.id;
      });
      let agencyListdataNew = [];
      for (let agency in agencyIdList) {
        if (existingAgnecyIdMap[agencyIdList[agency]["agency_id"]]) {
          agencyListdataNew.push({
            agency_id: agencyIdList[agency]["agency_id"],
            id: existingAgnecyIdMap[agencyIdList[agency]["agency_id"]],
          });
        } else {
          agencyListdataNew.push({
            agency_id: agencyIdList[agency]["agency_id"],
          });
        }
      }
      return agencyListdataNew;
    } else {
      return agencyIdList;
    }
  };
  const getSanitizedRegionList = (data) => {
    if (id) {
      if (regionChanged === false) {
        return data["resource_region_map"];
      } else {
        let existingRegionIdMap = {};
        resourcedataById.resource_region_map.forEach((region, index) => {
          existingRegionIdMap[region.region_id] = region.id;
        });
        let regionDataFrUpdate = [];
        regionDataSelected.forEach((region, index) => {
          if (existingRegionIdMap[region.region_id]) {
            regionDataFrUpdate.push({
              region_id: region.region_id,
              id: existingRegionIdMap[region.region_id],
              resource_id: id,
            });
          } else {
            regionDataFrUpdate.push({
              region_id: region.region_id,
              resource_id: id,
            });
          }
        });
        return regionDataFrUpdate;
      }
    } else {
      return regionDataSelected;
    }
  };

  const getContactListFrUpsert = () => {
    let selectedContact = getValues("contact_list");
    if (id) {
      let existingContactIdMap = {};
      resourcedataById.resource_contact_map.forEach((contact, index) => {
        existingContactIdMap[contact.contact_id] = contact.id;
      });
      let contactListdataNew = [];
      for (let contact in selectedContact) {
        let contactObj = selectedContact[contact];
        if (existingContactIdMap[contactObj["value"]]) {
          contactListdataNew.push({
            contact_id: contactObj["value"],
            id: existingContactIdMap[contactObj["value"]],
          });
        } else {
          contactListdataNew.push({ contact_id: contactObj["value"] });
        }
      }
      return contactListdataNew;
    } else {
      let contactListFrInsert = [];
      selectedContact.forEach((contact, index) => {
        contactListFrInsert.push({ contact_id: contact.value });
      });
      return contactListFrInsert;
    }
  };
  const navToResourceList = (msg) => {
    navigate("/resource-list");
  };

  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };

  // const [selectedOption, setSelectedOption] = useState(null);
  // console.log("radio option selected=", selectedOption);
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const tabsData = generateTabsData(resourceConfig);

  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="ResourceRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>

            <div className="d-flex justify-content-center">
              {activeTab > 0 && (
                <Button
                  variant="secondary"
                  className="previousbutton-style mx-2"
                  onClick={previousHandler}
                >
                  Previous
                </Button>
              )}
              {tabsData.length - 1 === activeTab && (
              <Button type="submit" className="mx-2" variant="primary" disabled={isDisabled}>
                {/* Save & Submit */}
                {t('saveAndSubmit')}
              </Button>
              )}
              {tabsData.length - 1 !== activeTab && (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={saveTabDataHandler}
                >
                  Save & Next
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateResourceRegForm;
