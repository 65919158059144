import * as React from "react";
const SvgMarkerInactive = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.25 20C40.25 34 23 46 23 46S5.75 34 5.75 20c0-4.774 1.817-9.352 5.052-12.728C14.037 3.896 18.425 2 23 2s8.963 1.896 12.198 5.272C38.433 10.648 40.25 15.226 40.25 20Z"
      fill="#FF4D2C"
      stroke="#FF4D2C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 26c3.176 0 5.75-2.686 5.75-6s-2.574-6-5.75-6-5.75 2.686-5.75 6 2.574 6 5.75 6Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMarkerInactive;
