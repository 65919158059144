import * as React from "react";
const SvgBulb = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#bulb_svg__a)" fill="#252B48">
      <path d="M13.533 0v3.867h1.934V0h-1.934ZM6.484 7.05l-2.9-2.9-1.367 1.367 2.9 2.9L6.484 7.05ZM23.884 8.417l2.9-2.9-1.368-1.367-2.9 2.9 1.368 1.367ZM14.5 5.8c-3.81 0-7.038 2.8-7.577 6.571l-.02.136a7.64 7.64 0 0 0 1.527 5.78c.74.958 1.237 1.872 1.237 2.789v3.09c0 .534.433.967.966.967h7.734a.967.967 0 0 0 .966-.966v-3.091c0-.917.496-1.831 1.238-2.789a7.64 7.64 0 0 0 1.526-5.78l-.02-.136A7.654 7.654 0 0 0 14.5 5.8ZM0 15.466h3.867v-1.933H0v1.934ZM25.133 15.466H29v-1.933h-3.867v1.934ZM11.6 29h5.8v-1.933h-5.8V29Z" />
    </g>
    <defs>
      <clipPath id="bulb_svg__a">
        <path fill="currentColor" d="M0 0h29v29H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBulb;
