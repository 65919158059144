import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Table from "../../../../Components/UiComponents/Table/Table";
import { getData, postDataWithBody, postDataWithBodyFrGIS } from "../../../../Services/Services";
import { getMatserUserTaskList } from "../../../../Services/EndPoints";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";

const UserTaskTable = () => {
  const { t } = useTranslation();
  const [userTaskData, setUserTaskData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isTextFieldDisable, setIsTextFieldDisable] = useState(true); // State to manage isTextFieldDisable
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const addNewHandler = () => {
    navigate("/my-tasks/create-usertask");
  };

  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
  setLoader(true);
    const response = await getData(
      getMatserUserTaskList,
      "",
      {}
    );
    console.log(response,"response")
    const masterUserTaskList = response || [];
    setLoader(false);
    setUserTaskData(masterUserTaskList);
    setRowCount(masterUserTaskList.length);
  };

  const handleClickView = () => {
    setIsTextFieldDisable(false); // Switch to editable mode when viewing
  };

  console.log(userTaskData,"usertaskdata")

  const columns = React.useMemo(
    () => [
      {
        Header: t("dailytaskmgmnttableheaders.name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("dailytaskmgmnttableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
        Cell: (props) => {
          return props.value === 0 ? "Activity" : "Notification";
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.assigntype"),
        accessor: "assign_type",
        disableSortBy: true,
        Cell: (props) => {
          return props.value === 0 ? "Group" : "Users";
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => (
          <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>
        ),
      },
      {
        Header: t("dailytaskmgmnttableheaders.action"),
        disableSortBy: true,
        Cell: (props) => (
          <div className="usertable__actionsicon">
            <span
              className="icon__position"
              onClick={() => {
                navigate("/my-tasks/create-usertask", {
                  state: {
                    data: props.row.original,
                    isTextFieldDisable: isTextFieldDisable,
                  },
                });
                handleClickView(); // Update state after navigation
              }}
            >
              <i
                className="cursor-pointer fa fa-eye ms-3"
                role="button"
                value={props.value}
              ></i>
            </span>
          </div>
        ),
      },
    ],
    [t, isTextFieldDisable, navigate]
  );

  return (
    <div>
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="container create-user">
        <div className="datatable__container">
          <Table
            data={userTaskData}
            addButtonLabel={t("dailytaskaddbuttonlabel")}
            addNewHandler={addNewHandler}
            columns={columns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTaskTable;
