import * as React from "react";
const SvgUploadCloud = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 12 9 9l-3 3M9 9v6.75"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.293 13.793A3.75 3.75 0 0 0 13.5 6.75h-.945A6 6 0 1 0 2.25 12.225"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12 9 9l-3 3"
      stroke="#252B48"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgUploadCloud;
