import * as React from "react";
const SvgInactive = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 22.917c5.753 0 10.417-4.664 10.417-10.417S18.253 2.084 12.5 2.084 2.084 6.747 2.084 12.5 6.747 22.917 12.5 22.917ZM5.135 5.135l14.73 14.73"
      stroke="#EB5757"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgInactive;
