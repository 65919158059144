import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import api from "../../../../Services/api";
import Loader from "react-spinner-loader";
import "./CreateUser.css";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";

import {
  keycloakbaseurl,
  agencyListURL,
  createJobTitle,
  regionListURL,
  getJobTitle,
  getAllRole,
  createUserintoRealm,
  assignRolesToUser,
  getUserDataByEmailAttr,
  updateUserByUserId,
  updateUserInAppURL,
  assignRoleToUser,
} from "../../../../Services/EndPoints";
import {
  schema,
  CreateUserConfig,
} from "../../../../JsonData/CreateUserConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  postDataWithBody,
  getData,
  putDataWithBody,
} from "../../../../Services/Services";
import ModalResourceType from "../../../../Components/UiComponents/ModalResourceType/ModalResourceType";
import { useTranslation } from "react-i18next";

const UserRegistrationForm = (props) => {
  let contactAgencyMapList = [];
  const { keycloak } = useKeycloak();
  const [userConfig, setUserConfig] = useState(CreateUserConfig);
  const [roleArr, setRoleArr] = useState([]);
  const [agencyData, setAgencyData] = useState([]);
  const [contactDataById, setContactDataById] = useState({});
  const [regionDataFrUpsert, setRegionDataFrUpsert] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionChanged, isRegionChanged] = useState(false);
  const [jobTitleList, setJobTitleList] = useState({});
  const [loader, setLoader] = useState(false);
  const [jobPopUp, setJobPopUp] = useState(false);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useLocation();
  const { t } = useTranslation();

  let dataFrEdit = state?.data;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

  useEffect(() => {
    getInitFormData();
  }, []);

  const getInitFormData = async () => {
    setLoader(true);
    await fetchAllRoles();
    await getRegionList();
    await getAllJobTitleList();
    let agencyList = await getAgencyList();
    if (dataFrEdit) {
      populateContactFormFrEdit(dataFrEdit, agencyList);
    }
    setLoader(false);
  };

  const fetchAllRoles = async () => {
    let roleIdMap = {};
    let rolearr = await getData(getAllRole);
    rolearr.forEach(function (role) {
      roleIdMap[role.id] = role;
      role.label = role.name;
      role.app_role_id = role.id;
      role.value = role.urm_role_id;
      role.id = role.urm_role_id;
    });
    CreateUserConfig[0].fields[2]["options"] = rolearr;
    setRoleArr(rolearr);
  };

  const getRegionList = async () => {
    var regionList = await getData(regionListURL);
    setRegionData(regionList);
    let hierregionData = flatToHierarchy(regionList, "parent_region_id");
    CreateUserConfig[0].fields[7]["options"] = hierregionData[0];
    setUserConfig(CreateUserConfig);
  };
  const getAllJobTitleList = async () => {
    var jbTitlList = await getData(getJobTitle);
    let jbTitlTemp = [];
    jbTitlList.forEach((title, index) => {
      jbTitlTemp.push({ label: title.value, value: title.id });
    });
    CreateUserConfig[0].fields[8]["options"] = jbTitlTemp;
    setJobTitleList(jbTitlTemp);
  };
  const flatToHierarchy = (flat, parent_key_name) => {
    var roots = []; // things without parent
    var all = {};
    flat.forEach(function (item) {
      all[item.id] = item;
    });
    Object.keys(all).forEach(function (id) {
      var item = all[id];
      item["id"] = item.id + "";
      item["label"] = item.name;
      item["value"] = item.id + "";
      if (item[parent_key_name] == 0) {
        roots.push(item);
      } else if (item[parent_key_name] in all) {
        var p = all[item[parent_key_name]];
        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });
    return roots;
  };

  const createJobTiltle = async (jobTitle) => {
    let input = { job_title: jobTitle, status: true };
    var resourceData = await postDataWithBody(createJobTitle, input, {});
    let jbTitlTemp = [
      ...jobTitleList,
      { label: jobTitle, value: resourceData.id },
    ];
    CreateUserConfig[0].fields[8]["options"] = jbTitlTemp;
    setUserConfig(CreateUserConfig);
    setJobTitleList(jbTitlTemp);
  };

  const populateContactFormFrEdit = async (formdata, agencyList) => {
    reset(formdata);
    let roleArr = [];
    formdata.user_role_map.forEach((roleObj) => {
      roleArr.push({
        value: roleObj.roleEntity.id,
        id: roleObj.roleEntity.id,
        app_role_id: roleObj.roleEntity.id,
        label: roleObj.roleEntity.name,
      });
    });
    setValue("role_list", roleArr);
    // setValue("send_notfication", formdata?.notification_on)
    let slectedRegions = [];
    formdata.user_region_map.forEach((user, index) => {
      slectedRegions.push(user.region_id + "");
    });
    let regionDataTemp = CreateUserConfig[0].fields[7].options;
    let regionDataWthSlctdValus = addSelectedFlag(
      [regionDataTemp],
      slectedRegions
    );
    setRegionData(regionDataWthSlctdValus);
    CreateUserConfig[0].fields[7]["options"] = regionDataWthSlctdValus[0];
    setUserConfig(CreateUserConfig);

    /*let contactdata = await postDataWithBody(findUsersById, [formdata.id], {});
    contactdata = contactdata[0];
    setContactDataById(contactdata);
    let agencyName = null,jobTitle = null;
    agencyList.forEach((agency, index) => {
      if(agency.value == contactdata.agency_id){
        agencyName = agency.label;
      }
    });


    let jobList = CreateUserConfig[0].fields[7]["options"];
    jobList.forEach((job, index) => {
      if(job.value == contactdata.job_title_id){
        jobTitle = job.label;
      }
    });
    setValue("agency", [{"label":agencyName,"value": contactdata.agency_id}]);

    let slectedRegions = [];
    contactdata.contact_region_map.forEach((contact, index) => {
      slectedRegions.push(contact.region_id+"");
    });
    let regionDataTemp = CreateUserConfig[0].fields[6].options;
    let regionDataWthSlctdValus = addSelectedFlag([regionDataTemp],slectedRegions);
    setRegionData(regionDataWthSlctdValus);
    CreateUserConfig[0].fields[6]["options"] =regionDataWthSlctdValus[0];
    setUserConfig(CreateUserConfig);
    console.log("regionDataWthSlctdValus[0]")*/
  };

  function addSelectedFlag(data, selectedValues) {
    return data.map(({ type, children = [], ...rest }) => {
      const o = { ...rest, type };
      if (selectedValues.indexOf(o.id) > -1) {
        o.checked = true;
      }
      if (children.length)
        o.children = addSelectedFlag(children, selectedValues);
      return o;
    });
  }
  const onHierDropdownChange = (currentNode, selectedNodes) => {
    isRegionChanged(true);
    let regionDataSlected = [];
    selectedNodes.forEach((region) => {
      regionDataSlected.push({ region_id: parseInt(region.id) });
    });
    setRegionDataFrUpsert(regionDataSlected);
    // console.log("regiondataslktd:",regionDataSlected);
    setValue("region",regionDataSlected);
  };

  const getAgencyList = async () => {
    var agencyList = await getData(agencyListURL);
    for (let agency in agencyList) {
      agencyList[agency]["label"] = agencyList[agency]["name"];
      agencyList[agency]["value"] = Number(agencyList[agency]["id"]);
    }
    //let hierData = flatToHierarchy(agencyList,"parent_agency_id");
    CreateUserConfig[0].fields[6]["options"] = agencyList;
    setUserConfig(CreateUserConfig);
    setAgencyData(agencyList);
    return agencyList;
  };

  const jobHandler = (props) => {
    setJobPopUp(!jobPopUp);
  };

  const handleAddJobTitle = async (jobTitle) => {
    // Call createJobTiltle with the entered job title
    await createJobTiltle(jobTitle);
    // Close the modal
    setJobPopUp(false);
  };

  function showSuccessMsg(configId) {
    //refreshConfigMasterTable(configId);
    toast.success("Job title added successfully.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
  }

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        {agencyData.length > 0 &&
          regionData.length > 0 &&
          jobTitleList.length > 0 && (
            <FormHandler
              register={register}
              errors={errors}
              fields={fields}
              onChange={onHierDropdownChange}
              control={control}
              columns={2}
              getValues={getValues}
            />
          )}
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const getLoggedInUserAuthToken = async () => {
    return keycloak.token;
  };
  const getInputFrURM = (data) => {
    let inputobj = {
      firstName: data.first_name,
      lastName: data.last_name,
      emailVerified: true,
      enabled: true,
      email: data.user_name,
      username: data.user_name,
      attributes: {
        address: [data.address],
        phone1: [data.phone_number],
        phone2: [data.secondary_phone_number],
        middleName: [data.middle_name],
      },
    };
    if (!dataFrEdit) {
      inputobj["credentials"] = [
        {
          type: "password",
          value: process.env.REACT_APP_NEW_USER_DEFAULT_PASSWORD,
          temporary: true,
        },
      ];
    }

    return inputobj;
  };

  const assignRolesToTheUser = async (data) => {
    var userId = await getUserDataByEmail(data.email);
    let url =
      keycloakbaseurl +
      assignRolesToUser
        .replace("{REALM}", process.env.REACT_APP_URM_REALM)
        .replace("{USER_ID}", userId);
    let headers = await getURMHeaderfSU();
    var rolesTobEAssigned = [];
    data.role_list.forEach(function (role) {
      rolesTobEAssigned.push(role.value);
    });
    const response = await api.postData(
      url,
      headers,
      JSON.stringify(rolesTobEAssigned)
    );
    // navToAllUsersPage("User Created Successfully.");
  };

  const getURMHeaderfSU = async () => {
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    return headers;
  };

  const getURMHeader = async () => {
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    return headers;
  };

  const getUserDataByEmail = async (email) => {
    let url =
      keycloakbaseurl +
      getUserDataByEmailAttr
        .replace("{REALM}", process.env.REACT_APP_URM_REALM)
        .replace("{EMAIL}", email);
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    const response = await api.getData(url, null, headers);

    return response?.data[0]?.id;
  };
  const assignRolesToTheUserInApp = async (data, inputobj) => {
    let headers = {
      "content-type": "application/json",
    };
    return await postDataWithBody(
      assignRoleToUser,
      getUserInput(data),
      headers
    );
  };
  const updateUserInApp = async (data, inputobj) => {
    let headers = {
      "content-type": "application/json",
    };
    let userInput = getUserInput(data);
    const response = await putDataWithBody(
      updateUserInAppURL,
      JSON.stringify(userInput),
      headers
    );
  };

  const getUserInput = (data) => {
    var rolesTobEAssigned = [];
    data.role_list.forEach(function (role) {
      rolesTobEAssigned.push({ role_id: role.app_role_id });
    });
    data["user_region_map"] =
      regionChanged === true ? regionDataFrUpsert : data.user_region_map;
    data["user_role_map"] = rolesTobEAssigned;
    return data;
  };
  const updateUserInURM = async (inputobj) => {
    var userId = await getUserDataByEmail(inputobj.email);
    console.log(userId);
    let url =
      keycloakbaseurl +
      updateUserByUserId.replace("{REALM}", process.env.REACT_APP_URM_REALM) +
      userId;
    let headers = await getURMHeader();
    try {
      const response = await api.putData(
        url,
        headers,
        JSON.stringify(inputobj)
      );
      if (response.status == 204) {
        toast.success("User Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        return true;
      } else {
        toast.error("Unable to update the user.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        return false;
      }
    } catch (e) {
      toast.error(e.response.data.errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      return false;
    }
  };
  const createUserInURM = async (inputobj) => {
    let url =
      keycloakbaseurl +
      createUserintoRealm.replace("{REALM}", process.env.REACT_APP_URM_REALM);
    let headers = await getURMHeader();
    try {
      const response = await api.postData(
        url,
        headers,
        JSON.stringify(inputobj)
      );
      if (response.status == 201) {
        toast.success("User Created Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        return true;
      }
    } catch (e) {
      toast.error(e.response.data.errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
      return false;
    }
  };

  const submitHandler = async (data, e) => {
    let msg = "";
    e.preventDefault();
    data["status"] = true;
    setLoader(true);
    if (dataFrEdit) {
      let inputobj = getInputFrURM(data);
      let isUserUpdatedInURM = await updateUserInURM(inputobj);
      if (isUserUpdatedInURM === true) {
        //await assignRolesToTheUser(data);
        await updateUserInApp(data, inputobj);
        setLoader(false);
        navigate("/users");
      }
    } else {
      let inputobj = getInputFrURM(data);
      data["email"] = inputobj.username;
      let isUserCreatedInURM = await createUserInURM(inputobj);
      if (isUserCreatedInURM === true) {
        //await assignRolesToTheUser(data);
        let res = await assignRolesToTheUserInApp(data, inputobj);
        setLoader(false);
        navigate("/users");
      }
    }
  };

  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };

  const tabsData = generateTabsData(userConfig);

  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="AgancyRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>
                      {/* <div className="add_new_title_user_container">
                        <span
                          // onClick={createJobTiltle}
                          className="add_new_title_user"
                          onClick={jobHandler}>
                          {t("addnew")}
                        </span>
                      </div> */}
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {activeTab > 0 && (
                <Button
                  variant="secondary"
                  className="previousbutton-style mx-2"
                  onClick={previousHandler}>
                  Previous
                </Button>
              )}
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {t("saveAndSubmit")}
                </Button>
              )}
              {tabsData.length - 1 !== activeTab && (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={saveTabDataHandler}>
                  save & Next
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      {jobPopUp && (
        <ModalResourceType
          titleValue={t("popup.title")}
          showSuccessMsg={showSuccessMsg}
          selectedConfigId={selectedConfigId}
          onAddJobTitle={handleAddJobTitle}
          show={jobPopUp}
          buttonLabel={t("popup.button")}
          handleToggle={jobHandler}
          popupLabel={t("popup.label")}
          {...props}
        />
      )}
    </div>
  );
};
export default UserRegistrationForm;
