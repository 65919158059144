import * as React from "react";
const SvgDownload = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.833 29.264V6.437M27.084 23.714l-5.251 5.55-5.252-5.55"
      stroke={props.stroke ? props.stroke : "currentColor"}
      strokeWidth={3.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.177 15.41h1.68c3.665 0 6.635 3.126 6.635 6.986v9.259c0 3.848-2.963 6.967-6.619 6.967H11.81c-3.665 0-6.636-3.128-6.636-6.986v-9.261c0-3.847 2.964-6.966 6.618-6.966h1.697"
      stroke={props.stroke ? props.stroke : "currentColor"}
      strokeWidth={3.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDownload;
