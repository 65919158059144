import * as React from "react";
const SvgResetRedIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#ResetRedIcon_svg__a)"
      stroke="#DE8080"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.542 2.167v3.25h3.25M12.459 10.833v-3.25h-3.25" />
      <path d="M11.099 4.875a4.875 4.875 0 0 0-8.044-1.82L.542 5.416m11.916 2.167L9.945 9.945A4.875 4.875 0 0 1 1.9 8.125" />
    </g>
    <defs>
      <clipPath id="ResetRedIcon_svg__a">
        <path fill="currentColor" d="M0 0h13v13H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgResetRedIcon;
