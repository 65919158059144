import * as React from "react";
const SvgRfidReader = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#b1b1b1"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M7.04 23.529v2.375H5.277v-6.527h2.138c1.774 0 2.661.643 2.661 1.928 0 .756-.369 1.341-1.107 1.755l1.902 2.844h-2l-1.384-2.375H7.04Zm0-1.326h.33c.617 0 .925-.272.925-.817 0-.45-.302-.674-.907-.674H7.04v1.49ZM13.201 25.904h-1.737v-6.527h3.858v1.415h-2.12v1.246h1.954v1.415h-1.955v2.45ZM16.393 25.904v-6.527h1.772v6.527h-1.772ZM25.12 22.502c0 1.09-.3 1.929-.901 2.518-.598.59-1.44.884-2.527.884h-2.111v-6.527h2.258c1.048 0 1.856.268 2.425.803.571.536.857 1.31.857 2.322Zm-1.83.063c0-.599-.119-1.042-.357-1.331-.235-.289-.593-.433-1.076-.433h-.513v3.656h.393c.536 0 .928-.154 1.178-.464.25-.312.375-.789.375-1.428ZM22.857 12.19a3.048 3.048 0 1 1-6.095 0 3.048 3.048 0 0 1 6.095 0Z" />
    <path d="M12.28 4.57 10.667 1.52h-7.62A3.048 3.048 0 0 0 0 4.57V28.95a3.048 3.048 0 0 0 3.048 3.048h24.38a3.048 3.048 0 0 0 3.049-3.048v-7.619l-3.048-1.613v9.232H3.048V4.57h9.232ZM30.396 18.24A12.134 12.134 0 0 0 32 12.19C32 5.459 26.542 0 19.81 0c-2.202 0-4.267.584-6.05 1.604l1.513 2.647A9.143 9.143 0 0 1 27.75 16.728l2.646 1.512Z" />
    <path d="M26.426 15.97A7.62 7.62 0 0 0 16.029 5.573L17.54 8.22a4.571 4.571 0 0 1 6.238 6.238l2.647 1.512Z" />
  </svg>
);
export default SvgRfidReader;
