import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
 url: process.env.REACT_APP_URM_BASE_URL+process.env.REACT_APP_URM_CONTEXT_NAME,
 realm: process.env.REACT_APP_URM_REALM,
 clientId: process.env.REACT_APP_URM_REALM_CLIENT,
});

// Function to initialize Keycloak
export const initKeycloak = (onAuthenticatedCallback, onSessionTimeout) => {
    keycloak
        .init({ onLoad: "login-required", checkLoginIframe: true })
        .then(authenticated => {
            if (authenticated) {
                console.log("Keycloak authenticated");
                onAuthenticatedCallback();
                setupSessionTimeoutHandler(onSessionTimeout);
            } else {
                keycloak.login();
            }
        })
        .catch(err => console.error("Keycloak initialization failed", err));
};

// Handle session timeout
const setupSessionTimeoutHandler = (onSessionTimeout) => {
    keycloak.onTokenExpired = async () => {
        try {
            // Call your API before logout
            await onSessionTimeout();
        } catch (error) {
            console.error("Error during session timeout handling", error);
        }
    };
};

// Export utility functions
export const getToken = () => keycloak.token;
export const isAuthenticated = () => keycloak.authenticated;

export default keycloak;
