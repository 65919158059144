import React, { useState } from "react";
import ModalResourceType from "../ModalResourceType/ModalResourceType";

const ResourceTypeDetails = (props) => {
  const [showResourceModal, setShowResourceModal] = useState(false);

  const resourceDetailsHandler = () => {
    setShowResourceModal(!showResourceModal);
  };

  return (
    <>
      <span
        className="save__resource--deatils"
        onClick={resourceDetailsHandler}
      >
        Add New
      </span>
      {showResourceModal && (
        <ModalResourceType
          show={showResourceModal}
          handleToggle={resourceDetailsHandler}
          {...props}
        />
      )}
    </>
  );
};

export default ResourceTypeDetails;
