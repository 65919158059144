import * as React from "react";
const SvgAlerttriangle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.145 1.93.91 9a1 1 0 0 0 .855 1.5h8.47A1 1 0 0 0 11.09 9L6.855 1.93a1 1 0 0 0-1.71 0v0ZM6 4.5v2M6 8.5h.005"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAlerttriangle;
