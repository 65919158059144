import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import useFormPersist from "react-hook-form-persist";
import "./CreateResourceEntityRegistrationForm.css";
//import { postDataWithBody, getData } from "../../../../Services/Services";

import { resourceFormConfig } from "../../../../JsonData/CreateResourceEntityConfig";
import { getData } from "../../../../Services/Services";
import { resourceCatListURL, resourceTypListURL } from "../../../../Services/EndPoints";
const CreateResourceEntityRegistrationForm = (props) => {
  const [resourceConfig, setResourceConfig] = useState(
    resourceFormConfig
  );
  
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useLocation();
  let id = state?.id;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getResourceTypeList();
    getResourceCategoryList();
  }, []);
  
async function getResourceTypeList() {
  var resourceList = await getData(resourceTypListURL);
  for (let resource in resourceList) {
    resourceList[resource]["label"] = resourceList[resource]["value"];
    resourceList[resource]["value"] = resourceList[resource]["id"];
  }
  resourceFormConfig[0].fields[1]["options"] = resourceList;
  setResourceConfig(resourceConfig);
}

async function getResourceCategoryList() {
  var resourceCategoryList = await getData(resourceCatListURL);
  for (let resourceCat in resourceCategoryList) {
    resourceCategoryList[resourceCat]["label"] =
      resourceCategoryList[resourceCat]["value"];
    resourceCategoryList[resourceCat]["value"] =
      resourceCategoryList[resourceCat]["id"];
  }
  resourceFormConfig[0].fields[0]["options"] = resourceCategoryList;
  setResourceConfig(resourceCategoryList);
}
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <FormHandler
        register={register}
        errors={errors}
        fields={fields}
        control={control}
        columns={2}
        getValues={getValues}
      />
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    e.preventDefault();
    //data.parent_agency_id = props.parentAgencyId;
    //var res = await postDataWithBody(createAgencyURL, data, {});
    //navToAgencyList(res);
  };

//   const navToAgencyList = (msg) => {
//     navigate("/agencylist", { state: { msg: msg } });
//   };

  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    
  };

  const tabsData = generateTabsData(resourceFormConfig);

  return (
    <div className="mt-4">
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="ResourceRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div>
              <span className="resourceregform__regionmap--position">
                <a
                  href="https://www.google.com/maps"
                  style={{ textDecoration: "underline",color:"green" }}
                >
                  Select Map
                </a>
              </span>
            </div>

            <div>
              <span className="resourceregform__sharelabel--position">
                Share{" "}
                <label className="radio-style">
                  <input
                    type="radio"
                    name="option"
                    value="yes"
                    //checked={selectedOption === "yes"}
                    onChange={handleOptionChange}
                  />
                  Yes
                </label>
                <label className="radio-style">
                  <input
                    type="radio"
                    name="option"
                    value="no"
                    //checked={selectedOption === "no"}
                    onChange={handleOptionChange}
                  />
                  No
                </label>
              </span>
            </div>

            <div className="d-flex justify-content-center">
              {activeTab > 0 && (
                <Button
                  variant="secondary"
                  className="previousbutton-style mx-2"
                  onClick={previousHandler}
                >
                  Previous
                </Button>
              )}
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  Save & Submit
                </Button>
              )}
              {tabsData.length - 1 !== activeTab && (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={saveTabDataHandler}
                >
                  Save & Next
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateResourceEntityRegistrationForm;