import * as React from "react";
const SvgMailIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.667 3.334h14.666c1.009 0 1.834.75 1.834 1.667v10c0 .916-.825 1.666-1.834 1.666H3.667c-1.009 0-1.834-.75-1.834-1.666V5c0-.917.825-1.667 1.834-1.667Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.167 5 11 10.833 1.833 5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMailIcon;
