import React, { useEffect, useState } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import "./DailyFloodReportEditableForm.css";
import { toast, ToastContainer } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import MajorRivers from "./MajorRivers/MajorRivers";
import {
  getDailyFloodReportListURL,
  misReportDataCumm,
  updateFloodReport,
} from "../../../../Services/EndPoints";
import { getData, postDataWithBody } from "../../../../Services/Services";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DailyReportSubForm from "./DailyReportSubForm/DailyReportSubForm";

const DailyFloodReportEditableForm = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [dailyFloodReportData, setDailyFloodReportData] = useState([]);
  const [riverList, setRiverList] = useState([]);

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader showIconsForback={true} title={t("editdailyform")} />
      ),
      haveOverflowVisible: true,
    },
  ];
  useEffect(() => {
    getDailyFloodReportList();
  }, []);

  const getDailyFloodReportList = async () => {
    const region_id = state.blockId;
    const reported_on = state.slctd_dt;
    const data = await getData(
      getDailyFloodReportListURL
        .replace("ID", region_id)
        .replace("DATE", reported_on)
    );
    let data2 = await postDataWithBody(
      misReportDataCumm,
      {
        region_id_list: [region_id],
        date: [reported_on],
        view_input: "dist_date",
      },
      {}
    );
    data[0].affected_population_rural_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_pop_rural_ystd || 0;
    data[0].affected_population_urban_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_pop_urban_ystd || 0;
    data[0].affected_families_rural_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_fam_rural_ystd || 0;
    data[0].affected_families_urban_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_fam_urban_ystd || 0;
    data[0].affected_animals_rural_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_anml_rural_ystd || 0;
    data[0].affected_animals_urban_yesterday = data2.mis_flood_report_district_date?.[0]?.aftd_anml_urban_ystd || 0;
    setDailyFloodReportData(data);
    if (data.length > 0) {
      const rivers = data[0].river_list.split(",").map(Number);
      setRiverList(rivers);
    }
  };

  const submitHandler = async (data, e) => {
 
    if (!data || typeof data !== "object") {
      console.error("Invalid data provided to submitHandler:", data);
      toast.error("Form submission failed due to invalid data.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }
 
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
 
    let idVal = data.id;
    delete data.id;
 
    let dataObj = {};
    if (e && e.target && e.target.name) {
      console.log(e.target.name);
      dataObj[e.target.name] = data;
    } else {
      console.warn("No event target found, manually assigning data.");
      dataObj = data; // Assign directly if `e.target` is missing
    }
 
    let inputObj = {
      id: idVal,
      daily_flood_report: dataObj,
    };
 
    try {
      let res = await postDataWithBody(updateFloodReport, inputObj, {});
      if (res.status === "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred while submitting the form.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
 
console.log("dailyFloodReportData:",dailyFloodReportData);
  return (
    <div>
      <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
        <GridItem layoutData={layoutData} />
      </div>
      <div style={{ border: "1px solid white", margin: "9px" }}>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("affected_areareport")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"affected_area_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t("affected_populationreport")}
            </Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"affected_details_report"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>{t("major_rivers")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
            <MajorRivers list={riverList}
              submitHandler={submitHandler}
              // idVal={idValue}
              dailyFloodReportData={dailyFloodReportData}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>{t("current_status")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"flood_impact_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {t("affected_agriculturereport")}
            </Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"area_affected_report"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>{t("damage_report")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"damaged_house_report"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>{t("fishingh_field")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"fishing_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>{t("death_report")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"death_report"}
              />
            </Accordion.Body>
          </Accordion.Item>


          <Accordion.Item eventKey="8">
            <Accordion.Header>{t("boat_usagereport")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"boats_report"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>{t("evacuation_report")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"evacuation_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header>{t("flood_reliefreport")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"flood_relief_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11">
            <Accordion.Header>{t("community_kitchenreport")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <DailyReportSubForm
                data={dailyFloodReportData}
                submitHandler={submitHandler}
                form_name={"community_kitchen_report"}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="12">
            <Accordion.Header>{t("health_center")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"health_center_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="13">
            <Accordion.Header>{t("relief_report")}</Accordion.Header>
            <Accordion.Body className="accordion-body-style">
              <div>
                <DailyReportSubForm
                  data={dailyFloodReportData}
                  submitHandler={submitHandler}
                  form_name={"relief_distribution_report"}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          
          
        </Accordion>
      </div>
    </div>
  );
};

export default DailyFloodReportEditableForm;
