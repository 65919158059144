import * as React from "react";
const SvgModeratecircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={35} cy={35} r={35} fill="#DB7816" fillOpacity={0.285} />
    <circle
      cx={35.648}
      cy={35.648}
      r={30.463}
      fill="#DB7816"
      fillOpacity={0.353}
    />
    <circle
      cx={35.972}
      cy={35.972}
      r={26.898}
      fill="#DB7816"
      fillOpacity={0.23}
    />
    <circle
      cx={35.324}
      cy={35.324}
      r={22.361}
      fill="#DB7816"
      fillOpacity={0.363}
    />
    <circle cx={35.648} cy={35.648} r={18.148} fill="#DB7816" />
  </svg>
);
export default SvgModeratecircle;
