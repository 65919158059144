import * as React from "react";
const SvgPtz5 = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.047 4.919 9.04 12.86l5.008-7.933L4.047 4.92Z"
      fill="#1C2039"
      stroke="#1C2038"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.566 10.475 9.272 12.24a.3.3 0 0 1-.483.001l-1.302-1.758"
      stroke="currentColor"
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPtz5;
