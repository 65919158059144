import * as React from "react";
const SvgComparisionDown = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.836 20.725c-1.87-11.446-12.664-19.21-24.11-17.34C9.278 5.255 1.515 16.05 3.385 27.496c1.87 11.447 12.665 19.21 24.11 17.34 11.447-1.87 19.21-12.665 17.34-24.111Z"
      fill="#40B2B7"
      fillOpacity={0.38}
    />
    <path
      d="M20.504 30.637a1.562 1.562 0 0 1 .488-3.085l5.486.869-8.088-11.232a1.575 1.575 0 0 1 .345-2.183 1.54 1.54 0 0 1 2.163.343l8.087 11.23.875-5.53a1.547 1.547 0 0 1 2.358-1.123 1.55 1.55 0 0 1 .7 1.607l-1.465 9.254-.006.04-.006.034-.014.088-.004.028-.002.01-.005.03-.005.031-.005.035a1.565 1.565 0 0 1-.83.88l-.03.013-.008-.001-.02.009-.02.007-.01-.001-.03.01c-.237.081-.49.102-.738.061l-9.216-1.424Z"
      fill="#40B2B7"
    />
  </svg>
);
export default SvgComparisionDown;
